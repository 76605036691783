import { Box, Typography } from "@mui/material";

const Footer = (props) => {
  return (
    <Box sx={props.styles}>
      <Typography variant="body2" align="center" color="primary">
        © {new Date().getFullYear()} MRSoft Consultoria e Desenvovimento de
        Sistemas. Todos os direitos reservados.
      </Typography>
    </Box>
  );
};
export default Footer;
