import { Alert } from "@mui/material";

const MyAlert = ({ messages, type }) => {
  let count = 1;
  return (
    <div style={{ marginTop: 20 }}>
      {messages &&
        messages.map((message) => (
          <Alert sx={{ margin: "5px 5% 5px 5%" }} severity={type} key={count++}>
            {message}
          </Alert>
        ))}
    </div>
  );
};

export default MyAlert;
