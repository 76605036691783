import { FormControl, InputLabel, MenuItem, TextField } from "@mui/material";
import styles from "./AddVehicle.module.css";
import { useContext, useEffect, useState } from "react";
import Botao from "../Botao";
import Select from "@mui/material/Select";
import FormDialog from "../FormDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import MaskedInput from "react-text-mask";
import Utils from "../../Utils";
import { get, post, remove } from "../../services/api";
import { ApplicationContext } from "../../contexts/Application";

const AddVehicle = ({ veiculo, setVeiculo, add, excluir }) => {
  const [placa, setPlaca] = useState(veiculo.placa);
  const [marca, setMarca] = useState(veiculo.marca);
  const [modelo, setModelo] = useState(veiculo.modelo);
  const [cor, setCor] = useState(veiculo.cor);

  const [novaMarca, setNovaMarca] = useState("");
  const [marcas, setMarcas] = useState([]);
  const [novoModelo, setNovoModelo] = useState("");
  const [modelos, setModelos] = useState([]);

  useEffect(() => {
    setPlaca(veiculo.placa);
    setMarca(veiculo.marca);
    setModelo(veiculo.modelo);
    setCor(veiculo.cor);
  }, [veiculo]);

  useEffect(() => {
    atualizaMarcas();
    if (marca) {
      atualizaModelos();
    }
  }, [marca]);

  const { setLoading, setMessages } = useContext(ApplicationContext);

  const atualizaMarcas = () => {
    get("/marca/all").then((resposta) => {
      let marcas = resposta.data.map((marca) => ({
        label: marca.descricao,
        value: marca.id,
      }));

      setMarcas([{ label: "Selecione", value: "" }, ...marcas]);
    });
  };
  const atualizaModelos = () => {
    get(`/modelo/all?idMarca=${marca}`).then((resposta) => {
      let modelos = resposta.data.map((modelo) => ({
        label: modelo.descricao,
        value: modelo.id,
      }));
      setModelos([{ label: "Selecione", value: "" }, ...modelos]);
    });
  };

  const formHandle = () => {
    const salvou = add(cor, placa, marca, modelo);
    salvou.then((resposta) => {
      if (resposta === true) {
        limpaCampos();
      }
    });
  };

  const limpaCampos = () => {
    setVeiculo({
      id: null,
      cor: "",
      placa: "",
      marca: "",
      modelo: "",
    });
  };

  const handleSaveMarca = async () => {
    setLoading(true);
    const resposta = await post("/marca/save", { descricao: novaMarca });
    let salvouMarca = false;
    if (resposta.messageType === "SUCCESS") {
      atualizaMarcas();
      setNovaMarca("");
      setModelo("");
      setMarca(resposta.data.id);
      salvouMarca = true;
    }

    window.scrollTo(0, 0);

    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });

    setLoading(false);
    return salvouMarca;
  };

  const handleDeleteMarca = async () => {
    setLoading(true);
    console.log("excluindo a marca ", marca);
    const resposta = await remove(`/marca/remove/${marca}`);
    if (resposta.messageType === "SUCCESS") {
      setMarca("");
      setModelo("");
      atualizaMarcas();
    }
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    setLoading(false);
    window.scrollTo(0, 0);
  };

  const handleSaveModelo = async () => {
    setLoading(true);
    const resposta = await post("/modelo/save", {
      descricao: novoModelo,
      marca: { id: marca },
    });
    if (resposta.messageType === "SUCCESS") {
      atualizaModelos();
      setModelo(resposta.data.id);
      setNovoModelo("");
    }

    window.scrollTo(0, 0);

    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });

    setLoading(false);
  };

  const handleDeleteModelo = async () => {
    setLoading(true);
    const resposta = await remove(`/modelo/remove/${modelo}`);
    if (resposta.messageType === "SUCCESS") {
      atualizaModelos();
      setModelo("");
    }
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    setLoading(false);
    window.scrollTo(0, 0);
  };

  const handlePlacaChange = (event) => {
    setPlaca(event.target.value.toUpperCase());
  };

  const handleExclude = () => {
    const excluiu = excluir(veiculo.id);
    excluiu.then((resposta) => {
      if (resposta === true) {
        limpaCampos();
      }
    });
  };

  return (
    <div className={styles.vehicle}>
      <div className={styles.campos}>
        <TextField
          required
          sx={{ width: "100%" }}
          id="placa"
          label="Placa"
          value={placa}
          variant="standard"
          fullWidth
          onChange={(event) => handlePlacaChange(event)}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {
              mask: Utils.placaMask,
            },
          }}
        />

        <div
          style={{
            display: "flex",
            gap: "1",
            alignItems: "flex-end",
            width: "100%",
          }}
        >
          <FormControl style={{ width: "100%" }}>
            <InputLabel>Marca *</InputLabel>
            <Select
              id="select-marca-veiculo"
              value={marca}
              label="Marca"
              onChange={(evento) => setMarca(evento.target.value)}
              variant="standard"
            >
              {marcas.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormDialog
            assunto={"Insira o nome da marca"}
            save={handleSaveMarca}
            textoBotaoEnviar="Salvar"
            icon={<AddCircleIcon color="primary" fontSize="small" />}
          >
            <TextField
              required
              sx={{ width: "100%" }}
              id="marca-modal"
              label="Marca"
              fullWidth
              value={novaMarca}
              variant="standard"
              onChange={(evento) =>
                setNovaMarca(
                  evento.target.value ? evento.target.value.toUpperCase() : null
                )
              }
            />
          </FormDialog>
          {marca && (
            <FormDialog
              assunto={"Exclusão de marca"}
              texto={"Tem certeza que deseja excluir essa marca?"}
              save={handleDeleteMarca}
              textoBotaoEnviar="Excluir"
              icon={<DoNotDisturbOnIcon color="primary" fontSize="small" />}
            />
          )}
        </div>

        <div
          style={{
            display: "flex",
            gap: "1",
            alignItems: "flex-end",
            width: "100%",
          }}
        >
          <FormControl style={{ width: "100%" }}>
            <InputLabel>Modelo *</InputLabel>
            <Select
              id="select-modelo-veiculo"
              value={modelo}
              label="Modelo"
              onChange={(evento) => setModelo(evento.target.value)}
              variant="standard"
              disabled={!marca}
            >
              {modelos.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {marca && (
            <FormDialog
              assunto={"Insira o nome do modelo"}
              save={handleSaveModelo}
              textoBotaoEnviar="Salvar"
              icon={<AddCircleIcon color="primary" fontSize="small" />}
            >
              <TextField
                required
                sx={{ width: "100%" }}
                id="modelol-modal"
                label="Modelo"
                fullWidth
                value={novoModelo}
                variant="standard"
                onChange={(evento) =>
                  setNovoModelo(
                    evento.target.value
                      ? evento.target.value.toUpperCase()
                      : null
                  )
                }
              />
            </FormDialog>
          )}
          {marca && modelo && (
            <FormDialog
              assunto={"Exclusão de modelo"}
              texto={"Tem certeza que deseja excluir esse modeo?"}
              save={handleDeleteModelo}
              textoBotaoEnviar="Excluir"
              icon={<DoNotDisturbOnIcon color="primary" fontSize="small" />}
            />
          )}
        </div>

        <TextField
          required
          sx={{ width: "100%" }}
          id="cor"
          label="Cor"
          fullWidth
          value={cor}
          variant="standard"
          onChange={(evento) =>
            setCor(
              evento.target.value ? evento.target.value.toUpperCase() : null
            )
          }
        />
      </div>
      <div className={styles.botao}>
        {veiculo.id && <Botao onClick={formHandle}>Atualizar</Botao>}
        {veiculo.id && (
          <Botao variant="outlined" onClick={handleExclude}>
            Excluir
          </Botao>
        )}
        {!veiculo.id && <Botao onClick={formHandle}>Adicionar</Botao>}
        <Botao onClick={limpaCampos}>Limpar</Botao>
      </div>
    </div>
  );
};

export default AddVehicle;
