import { TextField } from "@mui/material";
import styles from "./AddReading.module.css";
import { useState } from "react";
import Botao from "../Botao";
import MySelect from "../MySelect";

const AddReading = (props) => {
  const [leituraAtual, setLeituraAtual] = useState("");
  const [quantidade, setQuantidade] = useState("");

  const formHandle = () => {
    props.add({ quantidade, leituraAtual });
    props.setLeituraAnterior("");
    setLeituraAtual("");
    setQuantidade("");
  };

  const limpaCampos = () => {
    props.setDia("");
    props.setLeituraAnterior("");
    setLeituraAtual("");
    setQuantidade("");
  };

  const handleKeyDownButtonAdd = (event) => {
    if (event.key !== "Enter") {
      return;
    }
    if (
      ((props.leituraAnterior !== "" && leituraAtual !== "") ||
        quantidade !== "") &&
      props.tipo !== "" &&
      props.unidade !== null
    ) {
      formHandle();
    }
  };

  return (
    <div className={styles.reading}>
      <div className={styles.campos}>
        {props.tipo !== 4 && (
          <TextField
            sx={{ width: "100%" }}
            id="outlined-required"
            label="Leitura Anterior"
            type="number"
            value={props.leituraAnterior}
            onChange={(evento) => props.setLeituraAnterior(evento.target.value)}
          />
        )}
        {props.tipo !== 4 && (
          <TextField
            sx={{ width: "100%" }}
            id="outlined-required"
            label="Leitura atual"
            type="number"
            value={leituraAtual}
            inputRef={props.leituraAtualRef}
            onKeyDown={(event) => handleKeyDownButtonAdd(event)}
            onChange={(evento) => setLeituraAtual(evento.target.value)}
          />
        )}

        {props.tipo === 4 && (
          <TextField
            sx={{ width: "100%" }}
            id="outlined-required"
            label="Quantidade"
            type="number"
            onKeyDown={(event) => handleKeyDownButtonAdd(event)}
            value={quantidade}
            inputRef={props.quantidadeRef}
            onChange={(evento) => setQuantidade(evento.target.value)}
          />
        )}

        <MySelect
          sx={{ width: "100%" }}
          items={props.dias}
          label="Dia"
          method={props.setDia}
          value={props.dia}
        />
      </div>
      <div className={styles.botao}>
        <Botao
          onClick={formHandle}
          disabled={props.tipo === "" || props.unidade === null}
        >
          Adicionar
        </Botao>
        <Botao onClick={limpaCampos}>Limpar</Botao>
      </div>
    </div>
  );
};

export default AddReading;
