import { Button } from "@mui/material";
import styles from "./FileUpload.module.css";

const FileUpload = (props) => {
  const handleFileChange = (event) => {
    props.setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    props.handleUpload();
  };

  return (
    <div className={styles.fileUpload}>
      {!props.selectedFile && (
        <input
          accept="*.csv"
          style={{ display: "none" }}
          id="contained-button-file"
          type="file"
          onChange={handleFileChange}
        />
      )}

      <label className={styles.fileUpload} htmlFor="contained-button-file">
        {!props.selectedFile && (
          <Button
            variant="contained"
            color="primary"
            component="span"
            sx={{ padding: 0, border: 0 }}
          >
            Abrir arquivo (ar cond.)
          </Button>
        )}
      </label>

      {props.selectedFile && (
        <Button
          onClick={(event) => props.setSelectedFile(null)}
          variant="outlined"
          color="secondary"
        >
          Cancelar
        </Button>
      )}
      {props.selectedFile && (
        <Button
          title={props.selectedFile.name}
          onClick={handleUpload}
          variant="contained"
          color="success"
        >
          Enviar{" "}
          {props.selectedFile.name.length > 16
            ? props.selectedFile.name.substring(0, 15) + "..."
            : props.selectedFile.name}
        </Button>
      )}
    </div>
  );
};

export default FileUpload;
