import { createContext, useState } from "react";

export const ApplicationContext = createContext();

export const ApplicationProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState();
  const [messages, setMessages] = useState({ type: null, values: null });

  return (
    <ApplicationContext.Provider
      value={{
        loading,
        setLoading,
        title,
        setTitle,
        messages,
        setMessages,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationProvider;
