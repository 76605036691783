import { useState, useEffect, useContext } from "react";
import styles from "./Dependent.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ApplicationContext } from "../../contexts/Application";
import MyDataGrid from "../MyDataGrid";
import AddDependent from "../AddDependent";
import { get, post, put, remove } from "../../services/api";

const Dependent = ({ ocupante }) => {
  const { setTitle, setLoading, setMessages } = useContext(ApplicationContext);
  const [rows, setRows] = useState([]);
  const [dependente, setDependente] = useState({
    id: null,
    nome: "",
    rg: "",
    orgaoExpedidor: "",
    celular: "",
    tipoDependente: "",
  });

  useEffect(() => {
    setMessages({
      type: null,
      values: null,
    });
    setTitle("Dependentes");
  }, [setTitle, setMessages]);

  const atualizaDependentes = () => {
    get(`/dependente/dependentes/${ocupante.idEstadia}`).then((resposta) => {
      setRows(
        resposta.data.map((dependente) => ({
          id: dependente.id,
          idPessoa: dependente.pessoa.id,
          nome: dependente.pessoa.nome,
          rg: dependente.pessoa.rg,
          orgaoExpedidor: dependente.pessoa.orgaoExpedidor,
          tipoDependente: dependente.tipoDependente.descricao,
          celular: dependente.pessoa.celular,
        }))
      );
    });
  };

  useEffect(() => {
    ocupante.idEstadia && atualizaDependentes();
  }, [ocupante.idEstadia]);

  const salvarDependente = async (
    nome,
    rg,
    orgaoExpedidor,
    celular,
    tipoDependente
  ) => {
    console.log(nome, rg, orgaoExpedidor, celular, tipoDependente);
    let salvouDependente = false;
    const novo = {
      tipoDependente: { id: tipoDependente },
      pessoa: {
        nome,
        rg,
        orgaoExpedidor,
        celular,
      },
      idEstadia: ocupante.idEstadia,
    };
    console.log("dependente a salvar", novo);
    setLoading(true);
    let resposta = null;
    if (dependente.id) {
      console.log("update dependente");
      resposta = await put(`/dependente/update/${dependente.id}`, novo);
      if (resposta.messageType === "SUCCESS") {
        setDependente((dependentePrev) => ({ ...dependentePrev, id: null }));
        atualizaDependentes();
        salvouDependente = true;
      }
    } else {
      resposta = await post("/dependente/save", novo);
      if (resposta.messageType === "SUCCESS") {
        atualizaDependentes();
        salvouDependente = true;
      }
    }
    window.scrollTo(0, 0);

    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });

    setLoading(false);
    return salvouDependente;
  };

  const handleEdit = (id) => {
    get(`/dependente/find/${id}`).then((resposta) => {
      setDependente((prevState) => ({
        ...prevState,
        id: resposta.data.id,
        nome: resposta.data.pessoa.nome,
        idPessoa: resposta.data.pessoa.id,
        rg: resposta.data.pessoa.rg,
        orgaoExpedidor: resposta.data.pessoa.orgaoExpedidor,
        celular: resposta.data.pessoa.celular,
        tipoDependente: resposta.data.tipoDependente.id,
      }));
    });
  };

  const handleExclude = async (id) => {
    setLoading(true);
    let excluido = false;
    const resposta = await remove(`/dependente/remove/${id}`);

    if (resposta.messageType === "SUCCESS") {
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
      excluido = true;
    }
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    setLoading(false);
    window.scrollTo(0, 0);
    return excluido;
  };

  const columns = [
    {
      field: "nome",
      headerName: "Nome",
      type: "string",
      width: 250,
      editable: false,
    },
    {
      field: "rg",
      headerName: "RG",
      type: "String",
      width: 120,
      editable: false,
    },
    {
      field: "orgaoExpedidor",
      headerName: "Órgão Expedidor",
      type: "String",
      width: 120,
      editable: false,
    },
    {
      field: "celular",
      headerName: "Celular",
      type: "String",
      width: 150,
      editable: false,
    },
    {
      field: "tipoDependente",
      headerName: "Tipo",
      width: 100,
      editable: false,
      hideable: true,
    },

    {
      field: "acoes",
      headerName: "Ações",
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <EditIcon
              color="warning"
              style={{ cursor: "pointer" }}
              titleAccess="Editar"
              onClick={() => handleEdit(params.row.id)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.vehicles}>
      <section className={styles.header}>
        <div className={styles.readingAddReading}>
          <AddDependent
            add={salvarDependente}
            excluir={handleExclude}
            dependente={dependente}
            setDependente={setDependente}
          />
        </div>
        <MyDataGrid rows={rows} columns={columns} />
      </section>
    </div>
  );
};

export default Dependent;
