import React, { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import styles from "./PhotoUploader.module.css";
import noPhotoImage from "./no-photo.jpg";
import WebCamDialog from "../WebCamDialog";
import PhotoIcon from "@mui/icons-material/Photo";
import { ApplicationContext } from "../../contexts/Application";
import { get, post } from "../../services/api";
import Utils from "../../Utils";

const PhotoUploader = ({ idPessoa }) => {
  const { setLoading, setMessages } = useContext(ApplicationContext);
  const [photo, setPhoto] = useState(noPhotoImage);

  const handlePhotoChange = (event) => {
    const selectedPhoto = event.target.files[0];
    handleUpload(selectedPhoto);
  };

  useEffect(() => {
    if (idPessoa) {
      get(`/pessoa/photo/${idPessoa}`, { responseType: "blob" }).then(
        (resposta) => {
          if (resposta.data) {
            setPhoto(`data:;base64,` + resposta.data);
          } else {
            setPhoto(noPhotoImage);
          }
        }
      );
    }
  }, [idPessoa]);

  const handleCaptura = (image) => {
    console.log("Imagem da camera", image);
    const stringBase = image.replace(/^data:image\/(png|jpeg|jpg);base64,/, "");
    const blob = Utils.b64toBlob(stringBase);
    handleUpload(blob);
  };

  const handleUpload = async (image) => {
    console.log("Imagem do arquivo", image);
    setLoading(true);
    const formData = new FormData();
    formData.append("file", image);
    const resposta = await post(`/pessoa/photoupload/${idPessoa}`, formData);
    if (resposta.messageType === "SUCCESS") {
      setPhoto(URL.createObjectURL(image));
    }
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    window.scrollTo(0, 0);
    setLoading(false);
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        id="photo-input"
        style={{ display: "none" }}
        onChange={handlePhotoChange}
      />

      <div className={styles.photoUploader}>
        <div className={styles.imageContainer}>
          {photo && (
            <img className={styles.image} src={photo} alt="Foto selecionada" />
          )}
        </div>
        <label
          htmlFor="photo-input"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: 5,
          }}
        >
          <Button variant="outlined" style={{ width: "80%" }} component="span">
            <PhotoIcon />
          </Button>
          <WebCamDialog
            handleCaptura={handleCaptura}
            style={{ width: "100%" }}
          />
        </label>
      </div>
    </div>
  );
};

export default PhotoUploader;
