import { useState, useEffect, useContext } from "react";
import styles from "./AdditionalCharge.module.css";
import MyCard from "../MyCard";
import MyDataGrid from "../MyDataGrid";
import DeleteIcon from "@mui/icons-material/Delete";
import MySelect from "../MySelect";
import { post, remove, put, get } from "../../services/api";
import AddAdditionalCharge from "../AddAdditionalCharge";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { ApplicationContext } from "../../contexts/Application";
import { ExpenditureModuleContext } from "../../contexts/ExpenditureModule";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Button } from "@mui/material";

function AddicionalCharge() {
  const { setLoading, setTitle, setMessages } = useContext(ApplicationContext);

  const {
    tipo,
    setTipo,
    mes,
    setMes,
    ano,
    setAno,
    meses,
    anos,
    tipos,
    consumoFechado,
  } = useContext(ExpenditureModuleContext);

  const [rows, setRows] = useState([]);
  const [editingRows, setEditingRows] = useState([]);
  const [gastosJaCadastrados, setGastosJaCadastrados] = useState(false);

  useEffect(() => {
    setTitle("Custos adicionais");
  }, [setTitle]);

  useEffect(() => {
    get(`/gastoadicional/hasexpenditure/${mes}/${ano}`).then((resposta) => {
      setGastosJaCadastrados(resposta.data);
    });
  }, [mes, ano]);

  useEffect(() => {
    setMessages({ type: null, values: null });

    const filter = {
      idTipoConsumo: tipo,
      mes,
      ano,
    };

    post("/gastoadicional/filter", filter).then((resposta) => {
      setRows(
        resposta.data.map((gastoAdicional) => ({
          id: gastoAdicional.id,
          tipo: gastoAdicional.tipoConsumo.descricao,
          descricao: gastoAdicional.descricao,
          valor: gastoAdicional.valor,
          mes: gastoAdicional.mes,
          ano: gastoAdicional.ano,
        }))
      );
    });
  }, [tipo, mes, ano, setMessages]);

  const salvaGastoAdicional = async (gastoAdicional) => {
    setLoading(true);
    console.log("salvando gasto adicional ", gastoAdicional);
    const gastoAInserir = {
      tipoConsumo: { id: tipo },
      mes,
      ano,
      valor: gastoAdicional.valor,
      descricao: gastoAdicional.descricao,
    };
    console.log("salvando gasto adicional ", gastoAInserir);
    const resposta = await post("/gastoadicional/save", gastoAInserir);
    if (resposta.messageType === "SUCCESS") {
      setRows([
        ...rows,
        {
          id: resposta.data.id,
          tipo: resposta.data.tipoConsumo.descricao,
          descricao: resposta.data.descricao,
          valor: resposta.data.valor,
          mes: resposta.data.mes,
          ano: resposta.data.ano,
        },
      ]);
    }
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    window.scrollTo(0, 0);
    setLoading(false);
  };

  const handleEdit = (id) => {
    setEditingRows([...editingRows, id]);
  };

  const handleCancel = (id) => {
    setEditingRows(editingRows.filter((rowId) => rowId !== id));
  };

  const handleUpdate = async (params) => {
    setLoading(true);
    try {
      const resposta = await put(`/gastoadicional/update/${params.row.id}`, {
        valor: params.row.valor,
        descricao: params.row.descricao,
      });
      if (resposta.messageType === "SUCCESS") {
        setEditingRows(editingRows.filter((rowId) => rowId !== params.row.id));
      }
      setMessages({
        type: resposta.messageType.toLowerCase(),
        values: resposta.messages,
      });
      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const importarGastosMesAnterior = async () => {
    setLoading(true);
    try {
      const resposta = await post(`/gastoadicional/import/${mes}/${ano}`);
      if (resposta.messageType === "SUCCESS") {
        setRows(
          resposta.data.map((gastoAdicional) => ({
            id: gastoAdicional.id,
            tipo: gastoAdicional.tipoConsumo.descricao,
            descricao: gastoAdicional.descricao,
            valor: gastoAdicional.valor,
            mes: gastoAdicional.mes,
            ano: gastoAdicional.ano,
          }))
        );
        setGastosJaCadastrados(true);
      }
      setMessages({
        type: resposta.messageType.toLowerCase(),
        values: resposta.messages,
      });
      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleExclude = async (id) => {
    setLoading(true);
    const resposta = await remove(`/gastoadicional/remove/${id}`);
    console.log("status da exclusao", resposta);

    if (resposta.messageType === "SUCCESS") {
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
    }
    window.scrollTo(0, 0);
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    setLoading(false);
  };

  const columns = [
    {
      field: "tipo",
      headerName: "Tipo",
      editable: false,
      width: 100,
    },
    {
      field: "descricao",
      headerName: "Descricao",
      editable: true,
      width: 400,
    },

    {
      field: "valor",
      headerName: "Valor (R$)",
      type: "number",
      width: 150,
      editable: true,
    },

    {
      field: "delete",
      headerName: "Ações",
      sortable: false,
      width: 100,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (editingRows.includes(params.id)) {
          return (
            !consumoFechado && (
              <div>
                <SaveIcon
                  color="primary"
                  style={{ cursor: "pointer" }}
                  titleAccess="Salvar"
                  onClick={() => handleUpdate(params)}
                />
                <CloseIcon
                  color="action"
                  style={{ cursor: "pointer" }}
                  titleAccess="Cancelar"
                  onClick={() => handleCancel(params.row.id)}
                />
              </div>
            )
          );
        } else {
          return (
            !consumoFechado && (
              <div>
                <EditIcon
                  color="warning"
                  style={{ cursor: "pointer" }}
                  titleAccess="Editar"
                  onClick={() => handleEdit(params.row.id)}
                />
                <DeleteIcon
                  color="error"
                  style={{ cursor: "pointer" }}
                  titleAccess="Excluir gasto adicional mensal"
                  onClick={() => handleExclude(params.row.id)}
                />
              </div>
            )
          );
        }
      },
    },
  ];

  return (
    <>
      <div className={styles.addicionalCharge}>
        <section className={styles.addicionalChargeHeader}>
          <div className={styles.addicionalChargeMesAno}>
            <MySelect
              items={tipos}
              label="Tipo"
              method={(tipo) => setTipo(tipo)}
              value={tipo}
            />
            <MySelect
              items={meses}
              label="Mês"
              method={(mes) => setMes(mes)}
              value={mes}
            />
            <MySelect
              items={anos}
              label="Ano"
              method={(ano) => setAno(ano)}
              value={ano}
            />
            {!gastosJaCadastrados && (
              <Button
                variant="outlined"
                onClick={() => importarGastosMesAnterior()}
                endIcon={<SaveAltIcon />}
              >
                Importar Custos do Mês Anterior
              </Button>
            )}
          </div>
          {tipo !== "" && !consumoFechado && (
            <MyCard>
              <div className={styles.addAddicionalCharge}>
                <AddAdditionalCharge add={salvaGastoAdicional} tipo={tipo} />
              </div>
            </MyCard>
          )}
          <MyDataGrid rows={rows} columns={columns} />
        </section>
      </div>
    </>
  );
}

export default AddicionalCharge;
