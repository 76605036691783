import { useState, useEffect, useContext } from "react";
import styles from "./Configuration.module.css";
import MyDataGrid from "../MyDataGrid";
import { get, put } from "../../services/api";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { ApplicationContext } from "../../contexts/Application";

const Configuration = () => {
  const [rows, setRows] = useState([]);
  const [editingRows, setEditingRows] = useState([]);
  const { setLoading, setTitle, setMessages } = useContext(ApplicationContext);

  useEffect(() => {
    setTitle("Configurações");
    setMessages({ type: null, values: null });
  }, [setTitle, setMessages]);

  useEffect(() => {
    get("/configuracao/all").then((resposta) => {
      let configuracoes = resposta.data.map((configuracao) => ({
        propriedade: configuracao.propriedade,
        id: configuracao.id,
        valor: configuracao.valor,
      }));
      setRows(configuracoes);
    });
  }, []);

  const columns = [
    {
      field: "propriedade",
      headerName: "Propriedade",
      editable: false,
      width: 400,
    },

    {
      field: "valor",
      headerName: "Valor (R$)",
      type: "number",
      width: 150,
      editable: true,
    },
    {
      field: "delete",
      headerName: "Ações",
      sortable: false,
      width: 100,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const handleEdit = () => {
          setEditingRows([...editingRows, params.id]);
        };

        const handleCancel = () => {
          setEditingRows(editingRows.filter((rowId) => rowId !== params.id));
        };

        const handleSave = async () => {
          setLoading(true);
          try {
            const resposta = await put(`/configuracao/update/${params.id}`, {
              valor: params.row.valor,
            });
            if (resposta.messageType === "SUCCESS") {
              setEditingRows(
                editingRows.filter((rowId) => rowId !== params.id)
              );
            }
            setMessages({
              type: resposta.messageType.toLowerCase(),
              values: resposta.messages,
            });
            window.scrollTo(0, 0);
          } catch (error) {
            console.error(error);
          }
          setLoading(false);
        };

        if (editingRows.includes(params.id)) {
          return (
            <div>
              <SaveIcon
                color="primary"
                style={{ cursor: "pointer" }}
                titleAccess="Salvar"
                onClick={handleSave}
              />
              <CloseIcon
                color="action"
                style={{ cursor: "pointer" }}
                titleAccess="Cancelar"
                onClick={handleCancel}
              />
            </div>
          );
        } else {
          return (
            <EditIcon
              color="warning"
              style={{ cursor: "pointer" }}
              titleAccess="Editar"
              onClick={handleEdit}
            />
          );
        }
      },
    },
  ];

  return (
    <>
      <div className={styles.configuration}>
        <section className={styles.configurationHeader}>
          <MyDataGrid rows={rows} columns={columns} />
        </section>
      </div>
    </>
  );
}

export default Configuration;
