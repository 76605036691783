import { Outlet } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { useContext } from "react";
import { ApplicationContext } from "../../contexts/Application";
import styles from "./PatternPage.module.css";
import MyAlert from "../MyAlert";
import Sidebar from "../Sidebar";

const PatternPage = () => {
  const { loading, title, messages } = useContext(ApplicationContext);

  return (
    <main>
      <Sidebar>
        <div
          style={{
            backgroundColor: "#faf3e9",
            minHeight: "88vh",
            overflow: "hidden",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className={styles.title}>
            <h1 className={styles.h1}>{title}</h1>
          </div>
          <MyAlert messages={messages.values} type={messages.type} />
          <Outlet />
        </div>
      </Sidebar>
    </main>
  );
};

export default PatternPage;
