import { useState, useEffect, useContext } from "react";
import styles from "./Vehicle.module.css";
import MyCard from "../MyCard";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ApplicationContext } from "../../contexts/Application";
import MyDataGrid from "../MyDataGrid";
import AddVehicle from "../AddVehicle";
import { get, post, put, remove } from "../../services/api";

const Vehicle = ({ ocupante }) => {
  const { setTitle, setLoading, setMessages } = useContext(ApplicationContext);
  const [rows, setRows] = useState([]);
  const [veiculo, setVeiculo] = useState({
    id: null,
    placa: "",
    cor: "",
    marca: "",
    modelo: "",
  });

  useEffect(() => {
    setMessages({
      type: null,
      values: null,
    });
    setTitle("Veículos");
  }, [setTitle, setMessages]);

  useEffect(() => {
    ocupante.idPessoa &&
      get(`/veiculo/filter?idPessoa=${ocupante.idPessoa}`).then((resposta) => {
        setRows(
          resposta.data.map((veiculo) => ({
            id: veiculo.id,
            placa: veiculo.placa,
            marca: veiculo.modelo.marca.descricao,
            modelo: veiculo.modelo.descricao,
            cor: veiculo.cor,
          }))
        );
      });
  }, [ocupante.idPessoa]);

  const atualizaVeiculos = () => {
    get(`/veiculo/filter?idPessoa=${ocupante.idPessoa}`).then((resposta) => {
      setRows(
        resposta.data.map((veiculo) => ({
          id: veiculo.id,
          placa: veiculo.placa,
          marca: veiculo.modelo.marca.descricao,
          modelo: veiculo.modelo.descricao,
          cor: veiculo.cor,
        }))
      );
    });
  };

  const salvarVeiculo = async (cor, placa, marca, modelo) => {
    let salvouVeiculo = false;
    const novo = {
      placa,
      cor,
      modelo: { id: modelo, marca: { id: marca } },
      pessoa: { id: ocupante.idPessoa },
    };
    console.log("veiculo a salvar", novo);
    setLoading(true);
    let resposta = null;
    if (veiculo.id) {
      resposta = await put(`/veiculo/update/${veiculo.id}`, novo);
      if (resposta.messageType === "SUCCESS") {
        setVeiculo((veiculoPrev) => ({ ...veiculoPrev, id: null }));
        atualizaVeiculos();
        salvouVeiculo = true;
      }
    } else {
      resposta = await post("/veiculo/save", novo);
      if (resposta.messageType === "SUCCESS") {
        atualizaVeiculos();
        salvouVeiculo = true;
      }
    }

    window.scrollTo(0, 0);

    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });

    setLoading(false);
    return salvouVeiculo;
  };

  const handleEdit = (id) => {
    get(`/veiculo/find/${id}`).then((resposta) => {
      setVeiculo({
        id: resposta.data.id,
        placa: resposta.data.placa,
        marca: resposta.data.modelo.marca.id,
        modelo: resposta.data.modelo.id,
        cor: resposta.data.cor,
      });
      console.log(veiculo);
    });
  };

  const handleExclude = async (id) => {
    let excluiu = false;
    setLoading(true);
    const resposta = await remove(`/veiculo/remove/${id}`);

    if (resposta.messageType === "SUCCESS") {
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
      excluiu = true;
    }
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    setLoading(false);
    window.scrollTo(0, 0);
    return excluiu;
  };

  const columns = [
    {
      field: "placa",
      headerName: "Placa",
      description: "Placa.",
      type: "string",
      width: 120,
      editable: false,
    },
    {
      field: "marca",
      headerName: "Marca",
      type: "String",
      width: 120,
      editable: false,
    },
    {
      field: "modelo",
      headerName: "Modelo",
      type: "String",
      width: 120,
      editable: false,
    },
    {
      field: "cor",
      headerName: "Cor",
      type: "String",
      width: 120,
      editable: false,
    },

    {
      field: "acoes",
      headerName: "Ações",
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <EditIcon
              color="warning"
              style={{ cursor: "pointer" }}
              titleAccess="Editar"
              onClick={() => handleEdit(params.row.id)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.vehicles}>
      <section className={styles.header}>
        <MyCard>
          <div className={styles.readingAddReading}>
            <AddVehicle
              add={salvarVeiculo}
              veiculo={veiculo}
              setVeiculo={setVeiculo}
              excluir={handleExclude}
            />
          </div>
        </MyCard>
        <MyDataGrid rows={rows} columns={columns} />
      </section>
    </div>
  );
};

export default Vehicle;
