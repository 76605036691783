import { useState, useEffect, useContext } from "react";
import styles from "./Documents.module.css";
import MyCard from "../MyCard";
import DeleteIcon from "@mui/icons-material/Delete";
import PhotoIcon from "@mui/icons-material/Photo";
import { ApplicationContext } from "../../contexts/Application";
import MyDataGrid from "../MyDataGrid";
import { get, post, remove } from "../../services/api";
import AddDocument from "../AddDocument";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import WebCamDialog from "../WebCamDialog";
import Utils from "../../Utils";

const Documents = ({ ocupante }) => {
  const { setTitle, setLoading, setMessages } = useContext(ApplicationContext);
  const [rows, setRows] = useState([]);
  const [arquivo, setArquivo] = useState(null);
  const [verArquivo, setVerArquivo] = useState(false);
  const [document, setDocument] = useState({
    id: null,
    nome: "",
    pessoa: "",
  });

  useEffect(() => {
    setMessages({
      type: null,
      values: null,
    });
    setTitle("Documentos");
  }, [setTitle, setMessages]);

  useEffect(() => {
    ocupante &&
      get(`/ocupante/listFiles/${ocupante.idOcupante}`).then((resposta) => {
        setRows(
          resposta.data.map((arquivo) => ({
            id: arquivo.id,
            nome: arquivo.nome,
            pessoa: arquivo.nomePessoa,
            tamanho: arquivo.tamanho,
          }))
        );
      });
  }, [ocupante]);

  const atualizaArquivos = () => {
    get(`/ocupante/listFiles/${ocupante.idOcupante}`).then((resposta) => {
      setRows(
        resposta.data.map((arquivo) => ({
          id: arquivo.id,
          nome: arquivo.nome,
          pessoa: arquivo.nomePessoa,
          tamanho: arquivo.tamanho,
        }))
      );
    });
  };

  const visualizarDocumento = (id) => {
    get(`/arquivo/bytes/${id}`, { responseType: "blob" }).then((resposta) => {
      if (resposta.data) {
        setArquivo(`data:;base64,` + resposta.data);
        setVerArquivo(true);
      }
    });
  };

  const salvarArquivo = async (nome, pessoa) => {
    let salvouArquivo = false;
    const arquivo = {
      nome,
      idPessoa: pessoa,
    };
    setLoading(true);
    let resposta = null;
    resposta = await post("/arquivo/save", arquivo);
    if (resposta.messageType === "SUCCESS") {
      atualizaArquivos();
      salvouArquivo = true;
    }

    window.scrollTo(0, 0);

    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });

    setLoading(false);
    return salvouArquivo;
  };

  const handleExclude = async (id) => {
    setLoading(true);
    const resposta = await remove(`/arquivo/remove/${id}`);

    if (resposta.messageType === "SUCCESS") {
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
    }
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    setLoading(false);
    window.scrollTo(0, 0);
  };

  const handleFileChange = (event, id) => {
    const selectedPhoto = event.target.files[0];
    handleUpload(selectedPhoto, id);
  };

  const handleUpload = async (image, id) => {
    console.log("Imagem do arquivo", image);
    setLoading(true);
    const formData = new FormData();
    formData.append("file", image);
    const resposta = await post(`/arquivo/sendFile/${id}`, formData);
    if (resposta.messageType === "SUCCESS") {
      atualizaArquivos();
    }
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    window.scrollTo(0, 0);
    setLoading(false);
  };

  const handleCaptura = (image, id) => {
    console.log("Imagem da camera", image);
    const stringBase = image.replace(/^data:image\/(png|jpeg|jpg);base64,/, "");
    const blob = Utils.b64toBlob(stringBase);
    handleUpload(blob, id);
  };

  const style = {
    maxWidth: "800px", // Largura máxima do modal
    maxHeight: "800px", // Altura máxima do modal
    overflow: "hidden", // Impede que o conteúdo saia dos limites do modal durante o redimensionamento
  };

  const imageStyle = {
    maxWidth: "100%", // A imagem ocupará 100% do espaço disponível dentro do modal
    maxHeight: "100%", // A imagem ocupará 100% do espaço disponível dentro do modal
    objectFit: "contain", // A imagem se ajustará ao espaço disponível sem distorcer a proporção
  };
  const columns = [
    {
      field: "nome",
      headerName: "Nome do Arquivo",
      type: "string",
      width: 300,
      editable: false,
    },
    {
      field: "tamanho",
      headerName: "Tamanho",
      width: 200,
      type: "string",
      editable: false,
    },
    {
      field: "pessoa",
      headerName: "Nome do Titular",
      type: "String",
      width: 300,
      editable: false,
    },
    {
      field: "acoes",
      headerName: "Ações",
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div
            style={{ display: "flex", justifyContent: "space-between", gap: 5 }}
          >
            {params.row.tamanho === 0 && (
              <input
                type="file"
                accept="image/*"
                id="photo-input"
                style={{ display: "none" }}
                onChange={(event) => handleFileChange(event, params.row.id)}
              />
            )}
            {params.row.tamanho === 0 && (
              <label
                htmlFor="photo-input"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 5,
                }}
              >
                <PhotoIcon />
              </label>
            )}

            {params.row.tamanho === 0 && (
              <WebCamDialog
                semBotao={true}
                handleCaptura={handleCaptura}
                style={{ width: "100%" }}
                id={params.row.id}
                naoEhFoto={true}
              />
            )}

            {params.row.tamanho !== 0 && (
              <VisibilityIcon
                style={{ cursor: "pointer" }}
                titleAccess="Visualizar"
                onClick={() => visualizarDocumento(params.row.id)}
              />
            )}

            <DeleteIcon
              color="error"
              style={{ cursor: "pointer" }}
              titleAccess="Excluir"
              onClick={() => handleExclude(params.row.id)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.documents}>
      <section className={styles.header}>
        <MyCard>
          <div className={styles.readingAddReading}>
            <AddDocument
              add={salvarArquivo}
              document={document}
              setDocument={setDocument}
              ocupante={ocupante}
            />
          </div>
        </MyCard>
        <MyDataGrid rows={rows} columns={columns} />
      </section>
      <Dialog
        open={verArquivo}
        onClose={() => setVerArquivo(false)}
        classes={{ paper: "custom-dialog" }}
      >
        <DialogContent style={style}>
          {arquivo && <img src={arquivo} alt="Imagem" style={imageStyle} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVerArquivo(false)}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Documents;
