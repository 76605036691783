import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import LibraryAddRoundedIcon from "@mui/icons-material/LibraryAddRounded";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import HomeWorkRoundedIcon from "@mui/icons-material/HomeWorkRounded";
import HomeIcon from "@mui/icons-material/Home";
import ViewListIcon from "@mui/icons-material/ViewList";
import SettingsIcon from "@mui/icons-material/Settings";
import styles from "./Sidebar.module.css";

import logo from "../../images/hp.jpg";
import { Avatar, Collapse, Menu, MenuItem, Tooltip } from "@mui/material";
import { AuthContext } from "../../contexts/auth";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import DriveEtaIcon from "@mui/icons-material/DriveEta";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ children }) {
  const navigate = useNavigate();
  const { hasAuth } = React.useContext(AuthContext);

  const pages = [
    {
      label: "Consumos",
      path: "/expendituremodule/expenditure",
      icon: () => <MonetizationOnRoundedIcon />,
      role: "RATEIO",
    },
    {
      label: "Custos",
      path: "/expendituremodule/additionalcharge",
      icon: () => <LibraryAddRoundedIcon />,
      role: "RATEIO",
    },
    {
      label: "Leituras",
      path: "/expendituremodule",
      icon: () => <LocalLibraryRoundedIcon />,
      role: "RATEIO",
    },
    {
      label: "Unidades",
      path: "/expendituremodule/unit",
      icon: () => <HomeIcon />,
      role: "RATEIO",
    },
    {
      label: "Relatórios",
      icon: () => <AssessmentRoundedIcon />,
      role: "RATEIO",
      subpages: [
        {
          label: "Consolidado",
          path: "/expendituremodule/report",
          icon: () => <ViewListIcon />,
        },
        {
          label: "Por Unidade",
          path: "/expendituremodule/unitreport",
          icon: () => <HomeWorkRoundedIcon />,
        },
      ],
    },
    {
      label: "Configurações",
      path: "/expendituremodule/configurations",
      icon: () => <SettingsIcon />,
      role: "RATEIO",
    },

    {
      label: "Residentes/Visitantes",
      path: "/peoplemodule",
      icon: () => <PersonSearchIcon />,
      role: "PORTARIA",
    },
    {
      label: "Veículos",
      path: "/peoplemodule/vehicles",
      icon: () => <DriveEtaIcon />,
      role: "PORTARIA",
    },
  ];

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [openSubmenu, setOpenSubmenu] = React.useState(false);

  React.useEffect(() => {
    if (!open) {
      setOpenSubmenu(false);
    }

    if (openSubmenu) {
      setOpen(true);
    }
  }, [openSubmenu, open]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const { logout, user } = React.useContext(AuthContext);

  const settings = [{ label: "Logout", method: () => logout() }];

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            style={{ display: "flex" }}
          >
            <Box edge="start" sx={{ width: 70, marginTop: "6px" }}>
              <img src={logo} alt="Minha imagem" style={{ width: "100%" }} />
            </Box>
            <span style={{ marginTop: "18px" }}>Holanda's Prime</span>
          </Typography>
          <Box sx={{ marginLeft: "auto", marginRight: "2px" }}>
            <div style={{ gap: 10, display: "flex" }}>
              <div className={styles.menuBarH1} style={{ marginTop: 9 }}>
                {user.email}
              </div>
              <Tooltip title="Menu do usuário">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={user.email.toUpperCase()}
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting.label} onClick={setting.method}>
                    <Typography textAlign="center" onClick={setting.method}>
                      {setting.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {pages.map(
            (page, index) =>
              hasAuth(page.role) && (
                <ListItem
                  key={page.label}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    onClick={() => {
                      if (!page.subpages) {
                        navigate(page.path);
                      } else {
                        setOpenSubmenu(!openSubmenu);
                      }
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {page.icon()}
                    </ListItemIcon>
                    <ListItemText
                      primary={page.label}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {page.subpages && openSubmenu ? (
                      <ListItemIcon sx={{ minWidth: 0, ml: "auto" }}>
                        <ExpandMoreIcon />
                      </ListItemIcon>
                    ) : null}
                  </ListItemButton>

                  {page.subpages && openSubmenu ? (
                    <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
                      <List component="ul" disablePadding>
                        {page.subpages.map((subpage, subindex) => (
                          <ListItem
                            key={subpage.label}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              onClick={() => {
                                navigate(subpage.path);
                              }}
                              sx={{
                                minHeight: 48,
                                justifyContent: "initial",
                                pl: 4,
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  justifyContent: "center",
                                  mr: open ? 3 : "auto",
                                }}
                              >
                                {subpage.icon()}
                              </ListItemIcon>
                              <ListItemText primary={subpage.label} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  ) : null}
                  <Divider />
                </ListItem>
              )
          )}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}{" "}
      </Box>
    </Box>
  );
}
