import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../contexts/Application";
import PhotoUploader from "../PhotoUploader";
import styles from "./PersonalData.module.css";
import PeopleControlModuleContext from "../../contexts/PeopleControlModule";
import axios from "axios";
import MaskedInput from "react-text-mask";
import Utils from "../../Utils";
import { get, post, put } from "../../services/api";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const PersonalData = ({ ocupante, setOcupante, tipos }) => {
  const { setTitle, setLoading, setMessages } = useContext(ApplicationContext);
  const { unidades, setUnidades, tipoFiltro } = useContext(
    PeopleControlModuleContext
  );
  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setTitle("Residente/Visitante");
  }, [setTitle]);

  useEffect(() => {
    setMessages({ type: null, values: null });
  }, [setMessages]);

  const handleInputChange = async (event) => {
    if (event) {
      const inputValue = event.target.value;
      if (inputValue !== null && inputValue && inputValue.length > 1) {
        setLoadingAutocomplete(true);
        get(`/unidade/findResidencial?descricao=${inputValue}`).then(
          (resposta) => {
            setUnidades(
              resposta.data.map((unidade) => ({
                label: unidade.descricao,
                valor: unidade.id,
              }))
            );
          }
        );
      }
    }
  };

  const handleChangeCpf = (event) => {
    const consultaPessoaPorCpf = async (event) => {
      try {
        const response = await get(`/pessoa/find?cpf=${event.target.value}`);
        if (response.data) {
          setOcupante((prevState) => ({
            ...prevState,
            ...{
              idPessoa: response.data.id,
              nome: response.data.nome,
              rg: response.data.rg,
              orgaoExpedidor: response.data.orgaoExpedidor,
              cpf: response.data.cpf,
              nomeEmpresa: response.data.nomeEmpresa,
              profissao: response.data.profissao,
              email: response.data.email,
              telefone: response.data.telefone,
              celular: response.data.celular,
              logradouro: response.data.endereco
                ? response.data.endereco.logradouro
                : "",
              numero: response.data.endereco
                ? response.data.endereco.numero
                : "",
              complemento: response.data.endereco
                ? response.data.endereco.complemento
                : "",
              bairro: response.data.endereco
                ? response.data.endereco.bairro
                : "",
              cidade: response.data.endereco
                ? response.data.endereco.cidade
                : "",
              estado: response.data.endereco
                ? response.data.endereco.estado
                : "",
            },
          }));
        } else {
          setOcupante((prevState) => ({
            ...prevState,
            ...{
              idPessoa: null,
              nome: "",
              rg: "",
              orgaoExpedidor: "",
              cpf: event.target.value,
              nomeEmpresa: "",
              profissao: "",
              email: "",
              telefone: "",
              celular: "",
              logradouro: "",
              numero: "",
              complemento: "",
              bairro: "",
              cidade: "",
              estado: "",
            },
          }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (event.target.value && !event.target.value.includes("_")) {
      consultaPessoaPorCpf(event);
    } else {
      setOcupante((prevState) => ({
        ...prevState,
        cpf: event.target.value,
      }));
    }
  };

  const handleChangeCep = (event) => {
    const consultaEndereco = async (event) => {
      try {
        const response = await axios.get(
          `https://viacep.com.br/ws/${event.target.value}/json/`
        );
        if (response.data.logradouro) {
          setOcupante((prevState) => ({
            ...prevState,
            ...{
              logradouro: response.data.logradouro
                ? response.data.logradouro.toUpperCase()
                : null,
              numero: "",
              complemento: response.data.complemento,
              bairro: response.data.bairro
                ? response.data.bairro.toUpperCase()
                : null,
              cidade: response.data.localidade
                ? response.data.localidade.toUpperCase()
                : null,
              estado: response.data.uf ? response.data.uf.toUpperCase() : null,
              cep: event.target.value,
            },
          }));
        } else {
          setOcupante((prevState) => ({
            ...prevState,
            ...{
              logradouro: "",
              numero: "",
              complemento: "",
              bairro: "",
              cidade: "",
              estado: "",
              cep: event.target.value,
            },
          }));
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (event.target.value && !event.target.value.includes("_")) {
      consultaEndereco(event);
    } else {
      setOcupante((prevState) => ({
        ...prevState,
        cep: event.target.value,
      }));
    }
  };

  const handleSave = async () => {
    const ocupanteASalvar = {
      id: ocupante.idOcupante,
      tipoPessoa: {
        id: ocupante.tipoPessoa,
      },
      pessoa: {
        id: ocupante.idPessoa,
        nome: ocupante.nome,
        cpf: ocupante.cpf,
        rg: ocupante.rg,
        orgaoExpedidor: ocupante.orgaoExpedidor,
        profissao: ocupante.profissao,
        telefone: ocupante.telefone,
        celular: ocupante.celular,
        email: ocupante.email,
        nomeEmpresa: ocupante.nomeEmpresa,
        endereco: {
          logradouro: ocupante.logradouro,
          numero: ocupante.numero,
          cep: ocupante.cep,
          bairro: ocupante.bairro,
          cidade: ocupante.cidade,
          estado: ocupante.estado,
          complemento: ocupante.complemento,
        },
      },
      estadia: {
        id: ocupante.idEstadia,
        unidade: {
          id: ocupante.unidade ? ocupante.unidade.valor : null,
        },
        chegada: ocupante.chegada,
        saida: ocupante.saida,
      },
    };

    const path =
      "/ocupante/" +
      (ocupante.idOcupante ? `update/${ocupante.idOcupante}` : "save");
    setLoading(true);
    console.log("ocupanteASalvar", ocupanteASalvar);
    let resposta = null;
    if (ocupante.idOcupante) {
      resposta = await put(path, ocupanteASalvar);
    } else {
      resposta = await post(path, ocupanteASalvar);
    }

    if (resposta.messageType === "SUCCESS") {
      setOcupante((prevState) => ({
        ...prevState,
        ...{
          idPessoa: resposta.data.pessoa.id,
          idEstadia: resposta.data.estadia.id,
          idOcupante: resposta.data.id,
        },
      }));
    }

    window.scrollTo(0, 0);

    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });

    setLoading(false);
  };

  const handleChange = (name, value) => {
    setOcupante((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <div className={styles.personalData}>
        <Grid container spacing={4} columnSpacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} sm={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel>Tipo *</InputLabel>
              {tipos && tipos.length > 0 && (
                <Select
                  disabled={ocupante.idOcupante}
                  id="select-tipo-pessoa"
                  value={ocupante.tipoPessoa}
                  label="Tipo"
                  onChange={(event) =>
                    handleChange("tipoPessoa", event.target.value)
                  }
                  variant="standard"
                >
                  {tipos.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              required
              id="cpf"
              name="cpf"
              label="CPF"
              fullWidth
              variant="standard"
              value={ocupante.cpf}
              onChange={handleChangeCpf}
              InputProps={{
                inputComponent: MaskedInput,
                inputProps: {
                  mask: Utils.cpfMask,
                  placeholder: "999.999.999-99",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="nome"
              name="nome"
              label="Nome Completo"
              fullWidth
              autoComplete="given-name"
              variant="standard"
              value={ocupante.nome}
              onChange={(event) =>
                handleChange("nome", event.target.value.toUpperCase())
              }
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              id="rg"
              name="rg"
              label="RG"
              fullWidth
              variant="standard"
              value={ocupante.rg}
              onChange={(event) => handleChange("rg", event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="orgaoExpedidor"
              name="orgaoExpedidor"
              label="Órgão Exp."
              fullWidth
              variant="standard"
              value={ocupante.orgaoExpedidor}
              onChange={(event) =>
                handleChange("orgaoExpedidor", event.target.value.toUpperCase())
              }
            />
          </Grid>
          {ocupante.tipoPessoa !== 5 && (
            <Grid item xs={12} sm={3}>
              <TextField
                id="profissao"
                name="profissao"
                label="Profissão"
                fullWidth
                variant="standard"
                value={ocupante.profissao}
                onChange={(event) =>
                  handleChange("profissao", event.target.value.toUpperCase())
                }
              />
            </Grid>
          )}
          {ocupante.tipoPessoa === 5 && (
            <Grid item xs={12} sm={3}>
              <TextField
                id="profissao"
                name="profissao"
                label="Função"
                fullWidth
                variant="standard"
                value={ocupante.profissao}
                onChange={(event) =>
                  handleChange("profissao", event.target.value.toUpperCase())
                }
              />
            </Grid>
          )}
          {ocupante.tipoPessoa === 5 && (
            <Grid item xs={12} sm={4}>
              <TextField
                id="nomeEmpresa"
                name="nomeEmpresa"
                label="Nome da Empresa"
                fullWidth
                variant="standard"
                value={ocupante.nomeEmpresa}
                onChange={(event) =>
                  handleChange("nomeEmpresa", event.target.value.toUpperCase())
                }
              />
            </Grid>
          )}
          {ocupante.tipoPessoa !== 5 && (
            <Grid item xs={12} sm={4}>
              <TextField
                id="email"
                name="email"
                label="Email"
                fullWidth
                autoComplete="email"
                variant="standard"
                value={ocupante.email}
                onChange={(event) =>
                  handleChange("email", event.target.value.toUpperCase())
                }
              />
            </Grid>
          )}

          <Grid item xs={12} sm={3}>
            <TextField
              id="telefone"
              name="telefone"
              label="Telefone"
              fullWidth
              autoComplete="tel"
              variant="standard"
              value={ocupante.telefone}
              onChange={(event) => handleChange("telefone", event.target.value)}
              InputProps={{
                inputComponent: MaskedInput,
                inputProps: {
                  mask: Utils.telefoneMask,
                  placeholder: "(99) 9999-9999",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              required
              id="celular"
              name="celular"
              label="Celular"
              fullWidth
              autoComplete="tel"
              variant="standard"
              value={ocupante.celular}
              onChange={(event) => handleChange("celular", event.target.value)}
              InputProps={{
                inputComponent: MaskedInput,
                inputProps: {
                  mask: Utils.celularMask,
                  placeholder: "(99) 99999-9999",
                },
              }}
            />
          </Grid>
        </Grid>
        {ocupante.idPessoa && <PhotoUploader idPessoa={ocupante.idPessoa} />}
      </div>

      {ocupante.tipoPessoa === 3 && (
        <Grid
          container
          sx={{ marginTop: "0px" }}
          spacing={4}
          columnSpacing={{ md: 4 }}
        >
          <Grid item xs={12} sm={3}>
            <TextField
              required
              id="cep"
              name="cep"
              label="CEP"
              fullWidth
              autoComplete="shipping postal-code"
              value={ocupante.cep}
              onChange={handleChangeCep}
              variant="standard"
              InputProps={{
                inputComponent: MaskedInput,
                inputProps: {
                  mask: Utils.cepMask,
                  placeholder: "99999-999",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={7}>
            <TextField
              required
              id="logradouro"
              name="logradouro"
              label="Logradouro"
              fullWidth
              autoComplete="shipping address-line1"
              value={ocupante.logradouro}
              onChange={(event) =>
                handleChange("logradouro", event.target.value.toUpperCase())
              }
              variant="standard"
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              required
              id="numero"
              name="numero"
              label="Número"
              fullWidth
              variant="standard"
              value={ocupante.numero}
              onChange={(event) =>
                handleChange("numero", event.target.value.toUpperCase())
              }
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              id="complemento"
              name="complemento"
              label="Complemento"
              fullWidth
              variant="standard"
              value={ocupante.complemento}
              onChange={(event) =>
                handleChange("complemento", event.target.value.toUpperCase())
              }
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              required
              id="bairro"
              name="bairro"
              label="Bairro"
              fullWidth
              autoComplete="shipping address-level3"
              variant="standard"
              value={ocupante.bairro}
              onChange={(event) =>
                handleChange("bairro", event.target.value.toUpperCase())
              }
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              required
              id="cidade"
              name="cidade"
              label="Cidade"
              fullWidth
              autoComplete="shipping address-level2"
              value={ocupante.cidade}
              onChange={(event) =>
                handleChange("cidade", event.target.value.toUpperCase())
              }
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              required
              id="estado"
              name="estado"
              label="Estado"
              fullWidth
              value={ocupante.estado}
              onChange={(event) =>
                handleChange("estado", event.target.value.toUpperCase())
              }
              variant="standard"
            />
          </Grid>
        </Grid>
      )}
      <Grid
        container
        sx={{ marginTop: "10px" }}
        spacing={4}
        columnSpacing={{ md: -10 }}
      >
        <Grid item xs={12} sm={2}>
          <Autocomplete
            sx={{ width: "80%" }}
            value={ocupante.unidade}
            disablePortal
            loading={loadingAutocomplete}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onInputChange={handleInputChange}
            onChange={(event, novaUnidade) =>
              handleChange("unidade", novaUnidade)
            }
            id="auto-complete-unidade"
            options={unidades}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Unidade"
                variant="standard"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            locale={dayjs.locale("pt-br")}
          >
            <DatePicker
              label={ocupante.tipoPessoa === 5 ? "Dia " : "Chegada em"}
              value={ocupante.chegada}
              onChange={(chegada) => handleChange("chegada", chegada)}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </Grid>
        {ocupante.tipoPessoa !== 5 && (
          <Grid item xs={12} sm={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              locale={dayjs.locale("pt-br")}
            >
              <DatePicker
                label="Saída em"
                value={ocupante.saida}
                onChange={(saida) => handleChange("saida", saida)}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </Grid>
        )}
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          gap: "10px",
          marginTop: "10px",
        }}
      >
        <Stack spacing={10} direction="column">
          <Button
            sx={{
              width: "120px",
              height: "50px",
            }}
            onClick={() =>
              tipoFiltro === "P"
                ? navigate("/peoplemodule")
                : navigate("/peoplemodule/vehicles")
            }
            variant="outlined"
          >
            Voltar
          </Button>
        </Stack>
        <Stack spacing={10} direction="column">
          <Button
            sx={{
              width: "120px",
              height: "50px",
            }}
            onClick={handleSave}
            variant="contained"
          >
            Salvar
          </Button>
        </Stack>
      </div>
    </>
  );
};
export default PersonalData;
