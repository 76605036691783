import { TextField } from "@mui/material";
import styles from "./AddExpenditure.module.css";
import Botao from "../Botao";
import { useState } from "react";

const AddExpenditure = (props) => {
  const [valorTotal, setValorTotal] = useState("");
  const [valorUnitario, setValorUnitario] = useState("");
  const [quantidade, setQuantidade] = useState("");
  const formHandle = () => {
    props.add({
      valorTotal,
      valorUnitario,
      quantidade,
    });
    setValorTotal("");
    setValorUnitario("");
    setQuantidade("");
  };

  const limpaCampos = (event) => {
    setValorTotal("");
    setValorUnitario("");
    setQuantidade("");
  };

  return (
    <div className={styles.bill}>
      <div className={styles.campos}>
        {props.tipo !== 2 && (
          <TextField
            required
            sx={{ width: "100%" }}
            id="outlined-required"
            label="Quantidade"
            type="number"
            value={quantidade}
            onChange={(evento) => setQuantidade(evento.target.value)}
          />
        )}

        <TextField
          required
          sx={{ width: "100%" }}
          id="outlined-required"
          label="Valor Total"
          type="number"
          value={valorTotal}
          onChange={(evento) => setValorTotal(evento.target.value)}
        />
      </div>
      <div className={styles.botao}>
        <Botao onClick={formHandle}>Adicionar</Botao>
        <Botao onClick={limpaCampos}>Limpar</Botao>
      </div>
    </div>
  );
};

export default AddExpenditure;
