import { createContext, useContext, useEffect, useState } from "react";
import { get } from "../services/api";
import Utils from "../Utils";
import { AuthContext } from "./auth";

export const ExpenditureModuleContext = createContext();

export const ExpenditureModuleProvider = ({ children }) => {
  const { authenticated } = useContext(AuthContext);
  const [tipos, setTipos] = useState([]);
  const [tipo, setTipo] = useState("");
  const [ano, setAno] = useState(new Date().getFullYear());
  const anos = Utils.lastFiveYears(new Date().getFullYear());
  const [mes, setMes] = useState(new Date().getMonth() + 1);
  const [consumoFechado, setConsumoFechado] = useState(false);
  const meses = Utils.meses();

  useEffect(() => {
    if (authenticated) {
      get("/tipoconsumo/all").then((resposta) => {
        let tipos = resposta.data.map((tipo) => ({
          label: tipo.descricao,
          value: tipo.id,
        }));
        setTipos([{ label: "Todos", value: "" }, ...tipos]);
      });
    }
  }, [authenticated]);

  useEffect(() => {
    if (authenticated) {
      get(`/consumo/isclosed/${mes}/${ano}`).then((resposta) => {
        setConsumoFechado(resposta.data);
      });
    }
  }, [mes, ano, authenticated]);

  return (
    <ExpenditureModuleContext.Provider
      value={{
        tipo,
        setTipo,
        mes,
        setMes,
        ano,
        setAno,
        tipos,
        setTipos,
        meses,
        anos,
        consumoFechado,
        setConsumoFechado,
      }}
    >
      {children}
    </ExpenditureModuleContext.Provider>
  );
};

export default ExpenditureModuleProvider;
