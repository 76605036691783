import React, { useContext, useEffect, useState } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
} from "@mui/material";
import MySelect from "../MySelect";
import styles from "./ConsolidatedReport.module.css";
import { get } from "../../services/api";
import { ApplicationContext } from "../../contexts/Application";
import { saveAs } from "file-saver";
import Botao from "../Botao";
import Utils from "../../Utils";
import { ExpenditureModuleContext } from "../../contexts/ExpenditureModule";

const ConsolidatedReport = () => {
  const { setTitle, setLoading } = useContext(ApplicationContext);

  const { mes, setMes, ano, setAno, meses, anos, consumoFechado } = useContext(
    ExpenditureModuleContext
  );

  const [rows, setRows] = useState([]);

  useEffect(() => {
    setTitle("Relatório Consolidado");
  }, [setTitle]);

  useEffect(() => {
    if (consumoFechado) {
      get(`/leitura/consolidado/${mes}/${ano}`).then((resposta) => {
        setRows(resposta.data);
      });
    }
  }, [mes, ano, consumoFechado]);

  const gerarPdf = async () => {
    setLoading(true);
    const resposta = await get(`/leitura/pdfconsolidado/${mes}/${ano}`);
    const blob = Utils.b64toBlob(resposta.data, "application/pdf");
    console.log(blob);
    setLoading(false);
    saveAs(blob, `consolidado${mes}_${ano}.pdf`);
  };

  return (
    <div className={styles.report}>
      <section className={styles.reportHeader}>
        <div className={styles.reportMesAno}>
          <MySelect
            items={meses}
            key="mes"
            label="Mês"
            value={mes}
            method={(mes) => setMes(mes)}
          />
          <MySelect
            items={anos}
            key="ano"
            label="Ano"
            value={ano}
            method={(ano) => setAno(ano)}
          />
          {consumoFechado && (
            <Botao onClick={() => gerarPdf()}>Gerar PDF</Botao>
          )}
        </div>

        {consumoFechado && rows != null && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={styles.reportTableHeader}>
                <TableRow>
                  <TableCell>Descrição</TableCell>
                  <TableCell align="right">Quantidade</TableCell>
                  <TableCell align="right">Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={styles.reportTableBody}>
                {rows.map((dataItem) => (
                  <TableRow key={dataItem.descricao}>
                    <TableCell component="th" scope="row">
                      {dataItem.descricao}
                    </TableCell>
                    <TableCell align="right">
                      {dataItem.quantidade.toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="right">
                      {dataItem.valor.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </section>
    </div>
  );
};

export default ConsolidatedReport;
