import { FormControl, InputLabel, MenuItem, TextField } from "@mui/material";
import styles from "./AddDocument.module.css";
import { useEffect, useState } from "react";
import Botao from "../Botao";
import Select from "@mui/material/Select";
import { get } from "../../services/api";

const AddDocument = ({ document, setDocument, add, ocupante }) => {
  const [nome, setNome] = useState(document.nome);
  const [pessoa, setPessoa] = useState(document.pessoa);
  const [pessoas, setPessoas] = useState([]);

  useEffect(() => {
    setNome(document.nome);
    setPessoa(document.pessoa);
  }, [document]);

  const formHandle = () => {
    const salvou = add(nome, pessoa);
    salvou.then((resposta) => {
      if (resposta === true) {
        limpaCampos();
      }
    });
  };
  const limpaCampos = () => {
    setDocument({
      id: null,
      nome: "",
      pessoa: "",
    });
  };

  useEffect(() => {
    atualizaPessoas();
  }, []);

  const atualizaPessoas = () => {
    get(`/pessoa/find/pessoasEstadiaOcupante/${ocupante.idOcupante}`).then(
      (resposta) => {
        let pessoas = resposta.data.map((pessoa) => ({
          label: pessoa.nome,
          value: pessoa.id,
        }));
        setPessoas([{ label: "Selecione", value: "" }, ...pessoas]);
      }
    );
  };

  return (
    <div className={styles.document}>
      <div className={styles.campos}>
        <TextField
          required
          sx={{ width: "100%" }}
          id="nome"
          label="Nome do arquivo"
          fullWidth
          value={nome}
          variant="standard"
          onChange={(evento) =>
            setNome(
              evento.target.value ? evento.target.value.toUpperCase() : null
            )
          }
        />
        <FormControl style={{ width: "100%" }}>
          <InputLabel>Titular *</InputLabel>
          <Select
            id="select-dono-veiculo"
            value={pessoa}
            label="Titular"
            onChange={(evento) => setPessoa(evento.target.value)}
            variant="standard"
          >
            {pessoas.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={styles.botao}>
        <Botao onClick={formHandle}>Adicionar</Botao>
        <Botao onClick={limpaCampos}>Limpar</Botao>
      </div>
    </div>
  );
};

export default AddDocument;
