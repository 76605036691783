import { useState, useEffect, useRef, useContext } from "react";
import styles from "./Reading.module.css";
import MySelect from "../MySelect";
import AddReading from "../AddReading";
import MyCard from "../MyCard";
import Utils from "../../Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import { get, post, remove, put } from "../../services/api";
import { Autocomplete, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { ApplicationContext } from "../../contexts/Application";
import MyPagedDataGrid from "../MyPagedDataGrid";
import FileUpload from "../FileUpload";
import { ExpenditureModuleContext } from "../../contexts/ExpenditureModule";

const Reading = () => {
  const { setLoading, setTitle, setMessages } = useContext(ApplicationContext);

  const { mes, setMes, ano, setAno, meses, anos, consumoFechado } = useContext(
    ExpenditureModuleContext
  );

  const [tipos, setTipos] = useState([]);
  const [tipo, setTipo] = useState("");
  const unidadeRef = useRef();
  const leituraAtualRef = useRef();
  const quantidadeRef = useRef();
  const [rows, setRows] = useState([]);
  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
  const [unidade, setUnidade] = useState(null);
  const [unidades, setUnidades] = useState([]);
  const [dia, setDia] = useState(25);
  const dias = Utils.getDaysOfMonth(new Date().getMonth(), ano);
  const [leituraAnterior, setLeituraAnterior] = useState("");
  const [editingRows, setEditingRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    setTitle("Leituras");
  }, [setTitle]);

  const handleUpload = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    const resposta = await post(
      `/leitura/upload/${dia}/${mes}/${ano}`,
      formData
    );
    if (resposta.messageType === "SUCCESS") {
      setSelectedFile(null);
    }
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    window.scrollTo(0, 0);
    setLoading(false);
  };

  useEffect(() => {
    get("/tipoleitura/all").then((resposta) => {
      let tipos = resposta.data.map((tipo) => ({
        label: tipo.descricao,
        value: tipo.id,
      }));
      setTipos(tipos);
    });
  }, []);

  const handleInputChange = async (event) => {
    if (event && tipo) {
      const inputValue = event.target.value;
      if (inputValue !== null && inputValue && inputValue.length > 1) {
        setLoadingAutocomplete(true);
        get(`/unidade/find?descricao=${inputValue}&tipo=${tipo}`).then(
          (resposta) => {
            setUnidades(
              resposta.data.map((unidade) => ({
                label: unidade.descricao,
                valor: unidade.id,
              }))
            );
          }
        );
      }
    }
  };

  const handlePageChange = async (model, details) => {
    setPageNumber(model.page);
    setPageSize(model.pageSize);
  };

  useEffect(() => {
    if (tipo) {
      const path =
        `/leitura/filter?idTipoLeitura=${tipo}&pageNo=${pageNumber}&pageSize=${pageSize}&mes=${mes}&ano=${ano}` +
        (unidade ? `&idUnidade=${unidade.valor}` : "");

      get(path).then((resposta) => {
        setTotalElements(resposta.totalElements);

        setRows(
          resposta.data.map((leitura) => ({
            id: leitura.id,
            unidade: leitura.unidade.descricao,
            leituraAnterior: leitura.leituraAnterior,
            leituraAtual: leitura.leituraAtual,
            quantidade: leitura.quantidade,
            valor: leitura.valor,
            data:
              leitura.dia.toString().padStart(2, "0") +
              "/" +
              mes.toString().padStart(2, "0") +
              "/" +
              ano,
          }))
        );
      });
      if (!consumoFechado && unidade !== null && tipo !== "" && tipo !== 4) {
        const path = `/leitura/anterior?idTipoLeitura=${tipo}&mes=${mes}&ano=${ano}&idUnidade=${unidade.valor}`;
        get(path).then((resposta) => {
          setLeituraAnterior(
            resposta.data !== null ? resposta.data.leituraAtual : ""
          );

          leituraAtualRef.current.focus();
        });
      }
      if (!consumoFechado && unidade !== null && tipo !== "" && tipo === 4) {
        quantidadeRef.current.focus();
      }
    }
  }, [unidade, tipo, mes, ano, pageNumber, pageSize, consumoFechado]);

  useEffect(() => {
    setUnidades([]);
    setUnidade(null);
    setMessages({ type: null, values: null });
  }, [tipo, setMessages]);

  const salvarLeitura = async (leitura) => {
    setLoading(true);
    const leituraAInserir = {
      tipoLeitura: { id: tipo },
      unidade: { id: unidade.valor },
      mes,
      ano,
      dia,
      leituraAnterior,
      leituraAtual: leitura.leituraAtual,
      quantidade: leitura.quantidade,
    };

    const resposta = await post("/leitura/save", leituraAInserir);
    console.log("responsta ao salvar leitura", resposta);
    if (resposta.messageType === "SUCCESS") {
      if (tipo && tipo !== 4) {
        const resposta = await get(
          `/unidade/findnext/${unidade.valor}/${tipo}`
        );
        if (resposta.data != null) {
          setUnidade({
            valor: resposta.data.id,
            label: resposta.data.descricao,
          });
          leituraAtualRef.current !== undefined &&
            leituraAtualRef.current.focus();
        } else {
          setUnidade(null);
          unidadeRef.current !== undefined && unidadeRef.current.focus();
        }
      } else {
        setUnidade(null);
        unidadeRef.current !== undefined && unidadeRef.current.focus();
      }
      window.scrollTo(0, 0);
    }

    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    setLoading(false);
  };

  const handleEdit = (id) => {
    setEditingRows([...editingRows, id]);
  };

  const handleCancel = (id) => {
    setEditingRows(editingRows.filter((rowId) => rowId !== id));
  };

  const handleUpdate = async (params) => {
    setLoading(true);
    try {
      const resposta = await put(`/leitura/update/${params.row.id}`, {
        leituraAnterior: params.row.leituraAnterior,
        leituraAtual: params.row.leituraAtual,
        quantidade: params.row.quantidade,
      });
      if (resposta.messageType === "SUCCESS") {
        setEditingRows(editingRows.filter((rowId) => rowId !== params.row.id));
      }
      setMessages({
        type: resposta.messageType.toLowerCase(),
        values: resposta.messages,
      });
      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleExclude = async (id) => {
    setLoading(true);
    const resposta = await remove(`/leitura/remove/${id}`);

    if (resposta.messageType === "SUCCESS") {
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
    }
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    setLoading(false);
    window.scrollTo(0, 0);
  };

  const columns = [
    {
      field: "unidade",
      headerName: "Unidade",
      width: 100,
      editable: false,
      hideable: true,
    },

    {
      field: "data",
      headerName: "Data da Leitura",
      description: "Data da leitura.",
      type: "string",
      width: 120,
      editable: false,
    },
    {
      field: "leituraAnterior",
      headerName: "Leitura Anterior",
      type: "number",
      width: 120,
      editable: tipo !== 4,
    },
    {
      field: "leituraAtual",
      headerName: "Leitura Atual",
      type: "number",
      width: 100,
      editable: tipo !== 4,
    },
    {
      field: "quantidade",
      headerName: "Qtde Gasta",
      type: "number",
      width: 120,
      editable: tipo === 4,
      renderCell: (params) => {
        params.row.quantidade =
          tipo === 4
            ? params.row.quantidade
            : params.row.leituraAtual - params.row.leituraAnterior;
      },
    },

    {
      field: "valor",
      headerName: "Valor Total (R$)",
      type: "number",
      width: 120,
      editable: false,
    },

    {
      field: "delete",
      headerName: "Ações",
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (editingRows.includes(params.id)) {
          return (
            !consumoFechado && (
              <div>
                <SaveIcon
                  color="primary"
                  style={{ cursor: "pointer" }}
                  titleAccess="Salvar"
                  onClick={() => handleUpdate(params)}
                />
                <CloseIcon
                  color="action"
                  style={{ cursor: "pointer" }}
                  titleAccess="Cancelar"
                  onClick={() => handleCancel(params.row.id)}
                />
              </div>
            )
          );
        } else {
          return (
            !consumoFechado && (
              <div>
                <EditIcon
                  color="warning"
                  style={{ cursor: "pointer" }}
                  titleAccess="Editar"
                  onClick={() => handleEdit(params.row.id)}
                />
                <DeleteIcon
                  color="error"
                  style={{ cursor: "pointer" }}
                  titleAccess="Excluir"
                  onClick={() => handleExclude(params.row.id)}
                />
              </div>
            )
          );
        }
      },
    },
  ];

  const handleKeyDown = (event, novaUnidade) => {
    const inputValue = event.target.value;
    if (
      unidades.map((unidadeAux) => unidadeAux.label).includes(inputValue) &&
      unidades.length === 1
    ) {
      const novaUnidade = unidades.filter(
        (unidadeAux) => unidadeAux.label === inputValue
      );
      setUnidade(novaUnidade[0]);
    }
  };

  return (
    <>
      <div className={styles.reading}>
        <section className={styles.readingHeader}>
          <div className={styles.reading__filter}>
            <MySelect
              items={tipos}
              key="tipo"
              label="Tipo"
              value={tipo}
              method={(tipo) => setTipo(tipo)}
            />
            <Autocomplete
              sx={{ width: "25%" }}
              onKeyDown={(event) => handleKeyDown(event)}
              value={unidade}
              disablePortal
              loading={loadingAutocomplete}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onInputChange={handleInputChange}
              onChange={(event, novaUnidade) => setUnidade(novaUnidade)}
              id="combo-box-demo"
              options={unidades}
              renderInput={(params) => (
                <TextField inputRef={unidadeRef} {...params} label="Unidade" />
              )}
            />

            <MySelect
              items={meses}
              key="mes"
              label="Mês"
              value={mes}
              method={(mes) => setMes(mes)}
            />
            <MySelect
              items={anos}
              key="ano"
              label="Ano"
              value={ano}
              method={(ano) => setAno(ano)}
            />

            {!consumoFechado && tipo === 4 && (
              <FileUpload
                handleUpload={handleUpload}
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
              />
            )}
          </div>
          {!consumoFechado && (
            <MyCard>
              <div className={styles.readingAddReading}>
                <AddReading
                  setDia={(dia) => setDia(dia)}
                  add={salvarLeitura}
                  dias={dias}
                  dia={dia}
                  tipo={tipo}
                  unidade={unidade}
                  setLeituraAnterior={(leituraAnterior) =>
                    setLeituraAnterior(leituraAnterior)
                  }
                  leituraAnterior={leituraAnterior}
                  leituraAtualRef={leituraAtualRef}
                  quantidadeRef={quantidadeRef}
                />
              </div>
            </MyCard>
          )}
          <MyPagedDataGrid
            rows={rows}
            columns={columns}
            handlePageChange={handlePageChange}
            pageNumber={pageNumber}
            pageSize={pageSize}
            totalElements={totalElements}
          />
        </section>
      </div>
    </>
  );
};

export default Reading;
