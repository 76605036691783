import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api, createSessionApi } from "../services/api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const recoveredUser = localStorage.getItem("user");
    if (recoveredUser) {
      setUser(JSON.parse(recoveredUser));
    }
    setLoading(false);
  }, []);

  const login = async (email, senha) => {
    const response = await createSessionApi(email, senha);

    const token =
      response.messageType === "SUCCESS" ? response.data.token : null;

    console.log("token", token);

    if (token) {
      localStorage.setItem(
        "user",
        JSON.stringify({ email: response.data.email })
      );
      localStorage.setItem(
        "permissoes",
        JSON.stringify({ permissoes: response.data.permissoes })
      );
      localStorage.setItem("token", token);
      api.defaults.headers.Authorization = `Bearer ${token}`;
      setUser({ email });
      navigate("/peoplemodule");
    } else {
      setError(response.messages[0]);
    }
  };

  const logout = () => {
    setUser(null);
    api.defaults.headers.Authorization = null;
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/login");
    setError(null);
  };

  const hasAuth = (permission) => {
    const role = "ROLE_" + permission;
    console.log("role ", role);
    const permissions = localStorage.getItem("permissoes");
    if (permissions) {
      const permissoesArray = JSON.parse(permissions);
      return permissoesArray.permissoes.includes(role);
    }
    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!user,
        user,
        loading,
        login,
        logout,
        hasAuth,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
