import axios from "axios";

export const api = axios.create({
  baseURL: "https://mrsoft.net.br/api",
  //baseURL: "http://localhost:8080/api",
});

export const createSessionApi = async (email, senha) => {
  return api
    .post("/auth", { email, senha })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const post = async (path, dado) => {
  return api
    .post(path, dado, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        api.defaults.headers.Authorization = null;
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.href = "/login"; // ou qualquer outra URL desejada
      }
      return error.response.data;
    });
};

export const put = async (path, dado) => {
  return api
    .put(path, dado, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        api.defaults.headers.Authorization = null;
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.href = "/login"; // ou qualquer outra URL desejada
      }
      return error.response.data;
    });
};

export const remove = async (path, id) => {
  return api
    .delete(path, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: id,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        api.defaults.headers.Authorization = null;
        localStorage.removeItem("user");
        localStorage.removeItem("token");

        window.location.href = "/login"; // ou qualquer outra URL desejada
      }
      return error.response.data;
    });
};

export const get = async (path) => {
  return api
    .get(path, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        api.defaults.headers.Authorization = null;
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.href = "/login"; // ou qualquer outra URL desejada
      }
      return error.response.data;
    });
};
