import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import "./MyPagedDataGrid.css";
import { ptBR } from "@mui/x-date-pickers";

const MyPagedDataGrid = (props) => {
  return (
    <Box sx={{ marginTop: 5 }}>
      <DataGrid
        className={"datagrid"}
        rows={props.rows}
        columns={props.columns}
        page={props.pageNumber}
        pageSize={props.pageSize}
        rowCount={props.totalElements}
        paginationMode="server"
        pageSizeOptions={[5, 10, 25, 50]}
        getRowClassName={() => {
          return "colorRow";
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: props.pageSize,
              page: props.pageNumber,
            },
          },
        }}
        onPaginationModelChange={props.handlePageChange}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        disableColumnFilter={true}
        autoHeight={true}
      />
    </Box>
  );
};

export default MyPagedDataGrid;
