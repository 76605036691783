import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import "./MyDataGrid.css";

const MyDataGrid = (props) => {
  return (
    <Box sx={{ marginTop: 5}}>
      <DataGrid
        className={"datagrid"}
        rows={props.rows}
        columns={props.columns}
        pagination
        getRowClassName={() =>{ return "colorRow"}}
        initialState={{          
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSize={10}
        pageSizeOptions={[10]}      
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        disableColumnFilter={true}
        autoHeight={true}
        disablePageSize
        
    
      />
    </Box>
  );
};

export default MyDataGrid;
