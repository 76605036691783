import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const Botao = ({ onClick, variant, disabled, children }) => {
  const botaoHandle = () => {
    onClick();
  };

  return (
    <Stack spacing={2} direction="row">
      <Button
        onClick={botaoHandle}
        variant={variant ? "outlined" : "contained"}
        disabled={disabled}
      >
        {children}
      </Button>
    </Stack>
  );
};

export default Botao;
