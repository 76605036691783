import React, { useContext, useState, useRef, useEffect } from "react";
import styles from "./Login.module.css";
import logoLogin from "../../images/logo_hp_login.png";
import {
  Avatar,
  Button,
  TextField,
  Box,
  Typography,
  Alert,
} from "@mui/material";
import { AuthContext } from "../../contexts/auth";
import { Navigate } from "react-router-dom";
import Footer from "../Footer";

const Login = () => {
  const { login, authenticated, error } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const usernameRef = useRef(null);

  useEffect(() => {
    setUsername("");
    setPassword("");
    usernameRef.current.focus();
  }, [error]);

  const handleSubmit = (e) => {
    e.preventDefault();
    login(username, password);
  };

  if (authenticated) {
    return <Navigate to={"/peoplemodule"} />;
  }

  return (
    <div>
      {error && (
        <Alert sx={{ margin: "20px 20% 20px 20%" }} severity="error">
          {error}
        </Alert>
      )}

      <Box className={styles.box}>
        <Avatar
          className={styles.avatar}
          src={logoLogin}
          style={{ width: "100px", height: "100px" }}
        />
        <Typography component="h1" variant="h5">
          Login
        </Typography>

        <form onSubmit={handleSubmit} className={styles.form}>
          <TextField
            inputRef={usernameRef}
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button
            className={styles.botao}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Entrar
          </Button>
        </form>
      </Box>
      <Footer
        styles={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          backgroundColor: "#f5f5f5",
          py: 3,
        }}
      />
    </div>
  );
};

export default Login;
