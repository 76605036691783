import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Webcam from "react-webcam";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

const WebCamDialog = ({ handleCaptura, semBotao, id, naoEhFoto }) => {
  const [open, setOpen] = React.useState(false);

  const webcamRef = React.useRef(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();

    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (!naoEhFoto) {
        canvas.width = 350;
        canvas.height = 400;
        const sourceX = (image.width - 350) / 2;
        const sourceY = (image.height - 400) / 2;
        context.drawImage(image, sourceX, sourceY, 350, 400, 0, 0, 350, 400);
      } else {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0);
      }

      const capturedImageDataUrl = canvas.toDataURL("image/jpeg");
      if (id) {
        handleCaptura(capturedImageDataUrl, id);
      } else {
        handleCaptura(capturedImageDataUrl);
      }
    };

    image.src = imageSrc;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    capture();
    setOpen(false);
  };

  return (
    <div style={{ width: "100%" }}>
      {!semBotao && (
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          style={{ width: "100%", align: "right" }}
        >
          <PhotoCameraIcon />
        </Button>
      )}
      {semBotao && <PhotoCameraIcon onClick={handleClickOpen} />}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Capturar Imagem</DialogTitle>
        <DialogContent>
          <Webcam
            audio={false}
            ref={webcamRef}
            style={{ width: "100%" }}
            screenshotFormat="image/jpeg"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSave}>Capturar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WebCamDialog;
