import { FormControl, InputLabel, MenuItem, TextField } from "@mui/material";
import styles from "./AddDependent.module.css";
import { useEffect, useState } from "react";
import Botao from "../Botao";
import Select from "@mui/material/Select";
import PhotoUploader from "../PhotoUploader";
import MyCard from "../MyCard";
import { get } from "../../services/api";
import Utils from "../../Utils";
import MaskedInput from "react-text-mask";

const AddDependent = ({ add, dependente, setDependente, excluir }) => {
  const [tipos, setTipos] = useState([]);
  const [tipoDependente, setTipoDependente] = useState("");
  const [nome, setNome] = useState("");
  const [rg, setRg] = useState("");
  const [orgaoExpedidor, setOrgaoExpedidor] = useState("");
  const [celular, setCelular] = useState("");

  useEffect(() => {
    setNome(dependente.nome);
    setRg(dependente.rg);
    setOrgaoExpedidor(dependente.orgaoExpedidor);
    setCelular(dependente.celular);
    setTipoDependente(dependente.tipoDependente);
    console.log("funcao, exclude", handleExclude);
  }, [dependente]);

  useEffect(() => {
    get("/tipodependente/all").then((resposta) => {
      let tipos = resposta.data.map((tipoDependente) => ({
        label: tipoDependente.descricao,
        value: tipoDependente.id,
      }));

      setTipos(tipos);
    });
  }, []);

  const formHandle = () => {
    const salvou = add(nome, rg, orgaoExpedidor, celular, tipoDependente);
    salvou.then((resposta) => {
      if (resposta === true) {
        limpaCampos();
      }
    });
  };

  const handleExclude = () => {
    const excluiu = excluir(dependente.id);
    excluiu.then((resposta) => {
      if (resposta === true) {
        limpaCampos();
      }
    });
  };

  const limpaCampos = () => {
    setDependente({
      id: null,
      nome: "",
      rg: "",
      orgaoExpedidor: "",
      celular: "",
      tipoDependente: "",
    });
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ flex: "1", marginRight: "10px", marginTop: "-20px" }}>
        <MyCard
          style={{ display: "inline-block", justifyContent: "space-between" }}
        >
          <div className={styles.vehicle}>
            <div className={styles.campos}>
              <FormControl style={{ width: "40%" }}>
                <InputLabel>Tipo *</InputLabel>
                <Select
                  id="select-tipo-veiculo"
                  value={tipoDependente}
                  label="Tipo"
                  onChange={(evento) => setTipoDependente(evento.target.value)}
                  variant="standard"
                >
                  {tipos.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                required
                sx={{ width: "100%" }}
                id="nome"
                label="Nome"
                value={nome}
                variant="standard"
                fullWidth
                onChange={(evento) =>
                  setNome(
                    evento.target.value
                      ? evento.target.value.toUpperCase()
                      : null
                  )
                }
              />
              <TextField
                sx={{ width: "60%" }}
                id="rg"
                fullWidth
                label="RG"
                value={rg}
                variant="standard"
                onChange={(evento) => setRg(evento.target.value)}
              />

              <TextField
                sx={{ width: "35%" }}
                id="orgaoExpedidor"
                fullWidth
                label="Órgão Exp."
                value={orgaoExpedidor}
                variant="standard"
                onChange={(evento) =>
                  setOrgaoExpedidor(
                    evento.target.value
                      ? evento.target.value.toUpperCase()
                      : null
                  )
                }
              />

              <TextField
                id="celular"
                sx={{ width: "50%" }}
                name="celular"
                label="Celular"
                fullWidth
                autoComplete="tel"
                variant="standard"
                value={celular}
                onChange={(evento) => setCelular(evento.target.value)}
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {
                    mask: Utils.celularMask,
                    placeholder: "(99) 99999-9999",
                  },
                }}
              />
            </div>
            <div className={styles.botao}>
              {dependente.id && <Botao onClick={formHandle}>Atualizar</Botao>}
              {dependente.id && (
                <Botao variant="outlined" onClick={() => handleExclude()}>
                  Excluir
                </Botao>
              )}
              {!dependente.id && <Botao onClick={formHandle}>Adicionar</Botao>}
              <Botao onClick={limpaCampos}>Limpar</Botao>
            </div>
          </div>
        </MyCard>
      </div>
      {dependente.id && <PhotoUploader idPessoa={dependente.idPessoa} />}
    </div>
  );
};

export default AddDependent;
