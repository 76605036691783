import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styles from "./MySelect.module.css";

const MySelect = (props) => {
  const handleChange = (evento) => {
    props.method(evento.target.value);
  };

  return (
    <div className={styles.myselect}>
    <FormControl style={{ width: '100%' }}>
        <InputLabel>{props.label}</InputLabel>
        <Select
          id="select-mes"
          value={props.value}
          label={props.label}
          onChange={handleChange}
        >
          {props.items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MySelect;
