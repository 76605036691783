import React, { useContext, useEffect, useRef, useState } from "react";
import MySelect from "../MySelect";
import styles from "./UnitReport.module.css";
import { get } from "../../services/api";
import { ApplicationContext } from "../../contexts/Application";
import { saveAs } from "file-saver";
import Botao from "../Botao";
import Utils from "../../Utils";
import { Autocomplete, Button, TextField } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { ExpenditureModuleContext } from "../../contexts/ExpenditureModule";

const UnitReport = () => {
  const { setTitle, setLoading, setMessages } = useContext(ApplicationContext);

  const { mes, setMes, ano, setAno, meses, anos, consumoFechado } = useContext(
    ExpenditureModuleContext
  );

  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
  const [unidade, setUnidade] = useState(null);
  const [unidades, setUnidades] = useState([]);
  const unidadeRef = useRef();

  useEffect(() => {
    setTitle("Relatório por Unidade");
  }, [setTitle]);

  const handleInputChange = async (event) => {
    if (event) {
      const inputValue = event.target.value;
      if (inputValue !== null && inputValue && inputValue.length > 1) {
        setLoadingAutocomplete(true);
        get(`/unidade/findNotCommonArea?descricao=${inputValue}`).then(
          (resposta) => {
            setUnidades(
              resposta.data.map((unidade) => ({
                label: unidade.descricao,
                valor: unidade.id,
              }))
            );
          }
        );
      }
    }
  };

  const gerarPdf = async () => {
    setLoading(true);
    const resposta = await get(
      `/leitura/pdfunidade/${mes}/${ano}/${unidade.valor}`
    );
    const blob = Utils.b64toBlob(resposta.data, "application/pdf");
    setLoading(false);
    saveAs(
      blob,
      `prestacao_de_contas_unidade_${unidade.valor}_${mes}_${ano}_{}.pdf`
    );
  };

  const enviarEmailUnidade = async (unidade) => {
    setLoading(true);
    const resposta = await get(
      `/leitura/enviarpdfemail/${mes}/${ano}/${unidade.valor}`
    );
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    setLoading(false);
  };

  const enviarEmailTodasUnidades = async () => {
    setLoading(true);
    const resposta = await get(
      `/leitura/enviarpdfemailtodasunidades/${mes}/${ano}`
    );
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    setLoading(false);
  };

  return (
    <div className={styles.report}>
      <section className={styles.reportHeader}>
        <div className={styles.reportMesAno}>
          <Autocomplete
            sx={{ minWidth: 250 }}
            value={unidade}
            disablePortal
            loading={loadingAutocomplete}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onInputChange={handleInputChange}
            onChange={(event, novaUnidade) => setUnidade(novaUnidade)}
            id="combo-box-demo"
            options={unidades}
            renderInput={(params) => (
              <TextField inputRef={unidadeRef} {...params} label="Unidade" />
            )}
          />
          <MySelect
            items={meses}
            key="mes"
            label="Mês"
            value={mes}
            method={(mes) => setMes(mes)}
          />
          <MySelect
            items={anos}
            key="ano"
            label="Ano"
            value={ano}
            method={(ano) => setAno(ano)}
          />
          {consumoFechado && unidade && (
            <Botao onClick={() => gerarPdf()}>Gerar PDF</Botao>
          )}

          {consumoFechado && unidade && (
            <Button
              variant="contained"
              onClick={() => enviarEmailUnidade(unidade)}
              endIcon={<EmailIcon />}
              color="warning"
            >
              Enviar por email para unidade {unidade.label}
            </Button>
          )}

          {consumoFechado && !unidade && (
            <Button
              variant="contained"
              color="warning"
              onClick={() => enviarEmailTodasUnidades()}
              endIcon={<EmailIcon />}
            >
              Enviar por email para todas unidades
            </Button>
          )}
        </div>
      </section>
    </div>
  );
};

export default UnitReport;
