import { TextField } from "@mui/material";
import styles from "./AddAdditionalCharge.module.css";
import Botao from "../Botao";
import { useState } from "react";

const AddAdditionalCharge = (props) => {
  const [descricao, setDescricao] = useState("");
  const [valor, setValor] = useState("");

  const formHandle = () => {
    props.add({
      descricao,
      valor,
    });

    setValor("");
    setDescricao("");
  };

  const limpaCampos = (event) => {
    setValor("");
    setDescricao("");
  };

  return (
    <div className={styles.addicionalCharge}>
      <div className={styles.campos}>
        <TextField
          required
          sx={{ width: "100%" }}
          id="outlined-required"
          label="Descrição"
          type="text"
          value={descricao}
          onChange={(evento) => setDescricao(evento.target.value)}
        />

        <TextField
          required
          sx={{ width: "100%" }}
          id="outlined-required"
          label="Valor"
          type="number"
          value={valor}
          onChange={(evento) => setValor(evento.target.value)}
        />
      </div>
      <div className={styles.botao}>
        <Botao onClick={formHandle}>Adicionar</Botao>
        <Botao onClick={limpaCampos}>Limpar</Botao>
      </div>
    </div>
  );
};

export default AddAdditionalCharge;
