import { useState, useEffect, useRef, useContext } from "react";
import styles from "./Unit.module.css";
import { get, put } from "../../services/api";
import { Autocomplete, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { ApplicationContext } from "../../contexts/Application";
import MyPagedDataGrid from "../MyPagedDataGrid";

const Unit = () => {
  const { setLoading, setTitle, setMessages } = useContext(ApplicationContext);

  const unidadeRef = useRef();
  const [rows, setRows] = useState([]);
  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
  const [unidade, setUnidade] = useState(null);
  const [unidades, setUnidades] = useState([]);
  const [editingRows, setEditingRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    setTitle("Unidades");
  }, [setTitle]);

  const handleInputChange = async (event) => {
    if (event) {
      const inputValue = event.target.value;
      if (inputValue !== null && inputValue && inputValue.length > 1) {
        setLoadingAutocomplete(true);
        get(`/unidade/findNotCommonArea?descricao=${inputValue}`).then(
          (resposta) => {
            setUnidades(
              resposta.data.map((unidade) => ({
                label: unidade.descricao,
                email: unidade.email,
                valor: unidade.id,
              }))
            );
          }
        );
      }
    }
  };

  const handlePageChange = async (model, details) => {
    setPageNumber(model.page);
    setPageSize(model.pageSize);
  };

  useEffect(() => {
    const path =
      `/unidade/filter?pageNo=${pageNumber}&pageSize=${pageSize}` +
      (unidade ? `&idUnidade=${unidade.valor}` : "");

    get(path).then((resposta) => {
      setTotalElements(resposta.totalElements);

      setRows(
        resposta.data.map((unidade) => ({
          id: unidade.id,
          unidade: unidade.descricao,
          email: unidade.email,
          tipoUnidade: unidade.tipoUnidade,
        }))
      );
    });
  }, [unidade, pageNumber, pageSize]);

  useEffect(() => {
    setUnidades([]);
    setUnidade(null);
    setMessages({ type: null, values: null });
  }, [setMessages]);

  const handleEdit = (id) => {
    setEditingRows([...editingRows, id]);
  };

  const handleCancel = (id) => {
    setEditingRows(editingRows.filter((rowId) => rowId !== id));
  };

  const handleUpdate = async (params) => {
    setLoading(true);
    try {
      const resposta = await put(`/unidade/update/${params.row.id}`, {
        email: params.row.email,
      });
      if (resposta.messageType === "SUCCESS") {
        setEditingRows(editingRows.filter((rowId) => rowId !== params.row.id));
      }
      setMessages({
        type: resposta.messageType.toLowerCase(),
        values: resposta.messages,
      });
      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const columns = [
    {
      field: "unidade",
      headerName: "Unidade",
      width: 200,
      editable: false,
      hideable: true,
    },

    {
      field: "email",
      headerName: "Email",
      description: "Email.",
      type: "string",
      width: 200,
      editable: true,
    },
    {
      field: "tipoUnidade",
      headerName: "Tipo",
      type: "string",
      width: 200,
      editable: false,
    },

    {
      field: "acoes",
      headerName: "Ações",
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (editingRows.includes(params.id)) {
          return (
            <div>
              <SaveIcon
                color="primary"
                style={{ cursor: "pointer" }}
                titleAccess="Salvar"
                onClick={() => handleUpdate(params)}
              />
              <CloseIcon
                color="action"
                style={{ cursor: "pointer" }}
                titleAccess="Cancelar"
                onClick={() => handleCancel(params.row.id)}
              />
            </div>
          );
        } else {
          return (
            <div>
              <EditIcon
                color="warning"
                style={{ cursor: "pointer" }}
                titleAccess="Editar"
                onClick={() => handleEdit(params.row.id)}
              />
            </div>
          );
        }
      },
    },
  ];

  const handleKeyDown = (event, novaUnidade) => {
    const inputValue = event.target.value;
    if (
      unidades.map((unidadeAux) => unidadeAux.label).includes(inputValue) &&
      unidades.length === 1
    ) {
      const novaUnidade = unidades.filter(
        (unidadeAux) => unidadeAux.label === inputValue
      );
      setUnidade(novaUnidade[0]);
    }
  };

  return (
    <>
      <div className={styles.reading}>
        <section className={styles.readingHeader}>
          <div className={styles.readingMesAno}>
            <Autocomplete
              onKeyDown={(event) => handleKeyDown(event)}
              sx={{ minWidth: 250 }}
              value={unidade}
              disablePortal
              loading={loadingAutocomplete}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onInputChange={handleInputChange}
              onChange={(event, novaUnidade) => setUnidade(novaUnidade)}
              id="combo-box-demo"
              options={unidades}
              renderInput={(params) => (
                <TextField inputRef={unidadeRef} {...params} label="Unidade" />
              )}
            />
          </div>
          <MyPagedDataGrid
            rows={rows}
            columns={columns}
            handlePageChange={handlePageChange}
            pageNumber={pageNumber}
            pageSize={pageSize}
            totalElements={totalElements}
          />
        </section>
      </div>
    </>
  );
};

export default Unit;
