import { useState, useEffect, useContext, useRef } from "react";
import styles from "./Person.module.css";
import MySelect from "../MySelect";
import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete, TextField } from "@mui/material";
import MyPagedDataGrid from "../MyPagedDataGrid";
import { useNavigate } from "react-router-dom";
import { get } from "../../services/api";
import Botao from "../Botao";
import MyCard from "../MyCard";
import PeopleControlModuleContext from "../../contexts/PeopleControlModule";
import { ApplicationContext } from "../../contexts/Application";
import MaskedInput from "react-text-mask";
import Utils from "../../Utils";

function Vehicles() {
  const {
    unidades,
    setUnidades,
    vehicleFilter,
    setVehicleFilter,
    setTipoFiltro,
  } = useContext(PeopleControlModuleContext);
  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
  const [rows, setRows] = useState([]);
  const { setTitle, setMessages } = useContext(ApplicationContext);
  const [filtro, setFiltro] = useState(vehicleFilter);

  useEffect(() => {
    setMessages({ type: null, values: null });
    setTitle("Veículos");
  }, [setTitle]);

  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);

  const navigate = useNavigate();
  const unidadeRef = useRef();

  const atualizaMarcas = () => {
    get("/marca/all").then((resposta) => {
      let marcas = resposta.data.map((marca) => ({
        label: marca.descricao,
        value: marca.id,
      }));
      setMarcas([{ label: "Selecione", value: "" }, ...marcas]);
    });
  };

  const atualizaModelos = () => {
    get(`/modelo/all?idMarca=${filtro.marca}`).then((resposta) => {
      let modelos = resposta.data.map((modelo) => ({
        label: modelo.descricao,
        value: modelo.id,
      }));
      setModelos([{ label: "Selecione", value: "" }, ...modelos]);
    });
  };

  useEffect(() => {
    atualizaMarcas();
    if (filtro.marca) {
      atualizaModelos();
    }
  }, [filtro.marca]);

  const filtrarVeiculo = () => {
    if (
      filtro.placa ||
      filtro.marca ||
      filtro.modelo ||
      filtro.nomePessoa ||
      filtro.cor ||
      filtro.unidade
    ) {
      const path =
        "/veiculo/filter?" +
        `&pageNo=${filtro.pageNumber}` +
        `&pageSize=${filtro.pageSize}` +
        (filtro.nomePessoa ? `&nomePessoa=${filtro.nomePessoa}` : "") +
        (filtro.placa ? `&placa=${filtro.placa}` : "") +
        (filtro.cor ? `&cor=${filtro.cor}` : "") +
        (filtro.marca ? `&idMarca=${filtro.marca}` : "") +
        (filtro.modelo ? `&idModelo=${filtro.modelo}` : "") +
        (filtro.unidade ? `&idUnidade=${filtro.unidade.valor}` : "");
      console.log("path de veiculo ", path);

      get(path).then((resposta) => {
        setFiltro((prevState) => ({
          ...prevState,
          totalElements: resposta.totalElements,
        }));

        setRows(
          resposta.data.map((veiculo) => ({
            id: veiculo.id,
            placa: veiculo.placa,
            cor: veiculo.cor,
            modelo: veiculo.modelo.descricao,
            marca: veiculo.modelo.marca.descricao,
            idPesssoa: veiculo.pessoa.id,
            nomePessoa: veiculo.pessoa.nome,
            unidade: veiculo.unidade.descricao,
            idOcupante: veiculo.idOcupante,
          }))
        );
      });
    } else {
      setRows([]);
    }
  };

  useEffect(() => {
    filtrarVeiculo();
    //eslint-disable-next-line
  }, [
    filtro.marca,
    filtro.modelo,
    filtro.pageNumber,
    filtro.pageSize,
    filtro.unidade,
  ]);

  const handleInputChange = async (event) => {
    if (event) {
      const inputValue = event.target.value;
      if (inputValue !== null && inputValue && inputValue.length > 1) {
        setLoadingAutocomplete(true);
        get(`/unidade/findResidencial?descricao=${inputValue}`).then(
          (resposta) => {
            setUnidades(
              resposta.data.map((unidade) => ({
                label: unidade.descricao,
                valor: unidade.id,
              }))
            );
          }
        );
      }
    }
  };

  const handlePageChange = async (model, details) => {
    setFiltro((prevState) => ({
      ...prevState,
      ...{ pageNumber: model.page, pageSize: model.pageSize },
    }));
  };

  const handleEdit = (idOcupante) => {
    setVehicleFilter(filtro);
    setTipoFiltro("V");
    navigate(`/peoplemodule/addperson?idOcupante=${idOcupante}`);
  };

  const columns = [
    {
      field: "placa",
      headerName: "Placa",
      type: "string",
      width: 100,
      editable: false,
    },
    {
      field: "modelo",
      headerName: "Modelo",
      editable: false,
      width: 150,
    },
    {
      field: "marca",
      headerName: "Marca",
      type: "string",
      width: 120,
      editable: false,
    },
    {
      field: "cor",
      headerName: "Cor",
      type: "string",
      width: 100,
      editable: true,
    },
    {
      field: "unidade",
      headerName: "Unidade",
      type: "string",
      width: 80,
      editable: true,
    },
    {
      field: "nomePessoa",
      headerName: "Proprietário",
      type: "string",
      width: 300,
      editable: true,
    },
    {
      field: "acoes",
      headerName: "Editar",
      sortable: false,
      width: 70,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <EditIcon
              color="warning"
              style={{ cursor: "pointer" }}
              titleAccess="Editar"
              onClick={() => handleEdit(params.row.idOcupante)}
            />
          </div>
        );
      },
    },
  ];

  const handleChange = (name, value) => {
    setFiltro((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setPlaca = (placa) => {
    setFiltro((prevState) => ({
      ...prevState,
      placa,
    }));
  };

  const handlePlacaChange = (event) => {
    setPlaca(event.target.value.toUpperCase());
  };

  const handleKeyDownButtonAdd = (event) => {
    if (event.key !== "Enter") {
      return;
    }
    filtrarVeiculo();
  };

  return (
    <>
      <div className={styles.person}>
        <section className={styles.personHeader}>
          <MyCard>
            <div
              style={{
                margin: "40px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <div className={styles.personFilter}>
                <TextField
                  required
                  sx={{ width: "20%" }}
                  id="placa"
                  label="Placa"
                  value={filtro.placa}
                  fullWidth
                  onKeyDown={(event) => handleKeyDownButtonAdd(event)}
                  onChange={(event) => handlePlacaChange(event)}
                  InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: {
                      mask: Utils.placaMask,
                    },
                  }}
                />

                <MySelect
                  items={marcas}
                  label="Marca"
                  method={(marca) => handleChange("marca", marca)}
                  value={filtro.marca}
                />
                <MySelect
                  items={modelos}
                  label="Modelo"
                  method={(modelo) => handleChange("modelo", modelo)}
                  value={filtro.modelo}
                />

                <TextField
                  id="cor"
                  name="cor"
                  label="Cor"
                  sx={{ width: "20%" }}
                  value={filtro.cor}
                  onKeyDown={(event) => handleKeyDownButtonAdd(event)}
                  onChange={(event) =>
                    handleChange(
                      "cor",
                      event.target.value
                        ? event.target.value.toUpperCase()
                        : null
                    )
                  }
                />

                <TextField
                  sx={{ width: "50%" }}
                  id="nomePessoa"
                  name="nomePessoa"
                  onKeyDown={(event) => handleKeyDownButtonAdd(event)}
                  label="Nome"
                  value={filtro.nomePessoa}
                  onChange={(event) =>
                    handleChange(
                      "nomePessoa",
                      event.target.value
                        ? event.target.value.toUpperCase()
                        : null
                    )
                  }
                />

                <Autocomplete
                  sx={{ minWidth: 140 }}
                  value={filtro.unidade}
                  disablePortal
                  loading={loadingAutocomplete}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onInputChange={handleInputChange}
                  onChange={(event, novaUnidade) =>
                    handleChange("unidade", novaUnidade)
                  }
                  id="combo-box-demo"
                  options={unidades}
                  renderInput={(params) => (
                    <TextField
                      inputRef={unidadeRef}
                      {...params}
                      label="Unidade"
                    />
                  )}
                />
              </div>
              <div className={styles.botao} style={{ marginLeft: "auto" }}>
                <Botao onClick={filtrarVeiculo}>Pesquisar</Botao>
              </div>
            </div>
          </MyCard>

          <MyPagedDataGrid
            rows={rows}
            columns={columns}
            handlePageChange={handlePageChange}
            pageNumber={filtro.pageNumber}
            pageSize={filtro.pageSize}
            totalElements={filtro.totalElements}
          />
        </section>
      </div>
    </>
  );
}

export default Vehicles;
