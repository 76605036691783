import "./MyCard.css";

const MyCard = (props) => {
  return (
    <div className="card">
      <div>{props.children}</div>
    </div>
  );
};

export default MyCard;
