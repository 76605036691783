import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MyAlert from "../MyAlert";

const FormDialog = ({
  children,
  texto,
  icon,
  assunto,
  save,
  message,
  textoBotaoEnviar,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    await save();
    setOpen(false);
  };

  return (
    <div>
      <a
        href="#"
        style={{
          height: "26px",
          display: "flex",
          gap: "1",
          alignItems: "flex-start",
        }}
        onClick={handleClickOpen}
      >
        {icon}
      </a>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{assunto}</DialogTitle>
        {message && <MyAlert messages={message.values} type={message.type} />}
        <DialogContent>
          <DialogContentText>{texto}</DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSave}>{textoBotaoEnviar}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormDialog;
