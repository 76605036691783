import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styles from "./AddPerson.module.css";
import PersonalData from "../PersonalData";
import Vehicle from "../Vehicle";
import Dependent from "../Dependent";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { get } from "../../services/api";
import dayjs from "dayjs";
import { TabContext, TabPanel } from "@mui/lab";
import Documents from "../Documents";

const AddPerson = () => {
  const [value, setValue] = useState("1");
  const [ocupante, setOcupante] = useState(null);
  const [tipos, setTipos] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = useLocation();

  useEffect(() => {
    console.log("acionando busca por tipos de pessoas");
    get("/tipopessoa/all").then((resposta) => {
      let tipos = resposta.data.map((tipo) => ({
        label: tipo.descricao,
        value: tipo.id,
      }));
      setTipos(
        tipos.filter(
          (tipo) => tipo.label !== "DEPENDENTE" && tipo.label !== "ACOMPANHANTE"
        )
      );
    });
  }, []);

  const buscarOcupante = async (idOcupante) => {
    if (idOcupante) {
      const resposta = await get(`/ocupante/find/responsavel/${idOcupante}`);
      if (resposta.data) {
        setOcupante({
          unidade:
            resposta.data.estadia.unidade != null
              ? {
                  label: resposta.data.estadia.unidade.descricao,
                  valor: resposta.data.estadia.unidade.id,
                }
              : null,

          idPessoa: resposta.data.pessoa.id,
          idEstadia: resposta.data.estadia.id,
          idOcupante: resposta.data.id,
          tipoPessoa: resposta.data.tipoPessoa.id,
          nome: resposta.data.pessoa.nome,
          rg: resposta.data.pessoa.rg,
          cpf: resposta.data.pessoa.cpf,
          orgaoExpedidor: resposta.data.pessoa.orgaoExpedidor,
          profissao: resposta.data.pessoa.profissao,
          nomeEmpresa: resposta.data.pessoa.nomeEmpresa,
          telefone: resposta.data.pessoa.telefone,
          celular: resposta.data.pessoa.celular,
          email: resposta.data.pessoa.email,
          cep: resposta.data.pessoa.endereco.cep,
          logradouro: resposta.data.pessoa.endereco.logradouro,
          numero: resposta.data.pessoa.endereco.numero,
          complemento: resposta.data.pessoa.endereco.complemento,
          bairro: resposta.data.pessoa.endereco.bairro,
          cidade: resposta.data.pessoa.endereco.cidade,
          estado: resposta.data.pessoa.endereco.estado,
          chegada:
            resposta.data.estadia.chegada != null
              ? dayjs(resposta.data.estadia.chegada)
              : null,
          saida:
            resposta.data.estadia.saida != null
              ? dayjs(resposta.data.estadia.saida)
              : null,
        });
      }
    } else {
      setOcupante({
        unidade: null,
        idPessoa: null,
        idEstadia: null,
        idOcupante: null,
        tipoPessoa: "",
        nome: "",
        rg: "",
        cpf: "",
        orgaoExpedidor: "",
        profissao: "",
        nacionalidade: "",
        telefone: "",
        celular: "",
        email: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
        chegada: null,
        saida: null,
      });
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const idOcupante = searchParams.get("idOcupante");
    buscarOcupante(idOcupante);
  }, [location.search]);

  return (
    <div className={styles.aba}>
      <section className={styles.header}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange}>
              {ocupante && (
                <Tab label="Dados Pessoais" icon={<PersonIcon />} value="1" />
              )}
              {ocupante && ocupante.idOcupante && (
                <Tab label="Veículos" icon={<DriveEtaIcon />} value="2" />
              )}
              {ocupante && ocupante.idOcupante && ocupante.tipoPessoa !== 3 && (
                <Tab label="Dependentes" icon={<Diversity3Icon />} value="3" />
              )}
              {ocupante && ocupante.idOcupante && ocupante.tipoPessoa === 3 && (
                <Tab
                  label="Acompanhantes"
                  icon={<Diversity3Icon />}
                  value="3"
                />
              )}
              {ocupante && ocupante.idOcupante && (
                <Tab label="Documentos" icon={<DescriptionIcon />} value="4" />
              )}
            </Tabs>

            <TabPanel value="1">
              {ocupante && (
                <PersonalData
                  ocupante={ocupante}
                  setOcupante={setOcupante}
                  tipos={tipos}
                />
              )}
            </TabPanel>
            {ocupante && ocupante.idOcupante && (
              <TabPanel value="2">
                <Vehicle ocupante={ocupante} />
              </TabPanel>
            )}
            {ocupante && ocupante.idOcupante && (
              <TabPanel value="3">
                <Dependent ocupante={ocupante} />
              </TabPanel>
            )}
            {ocupante && ocupante.idOcupante && (
              <TabPanel value="4">
                {" "}
                <Documents ocupante={ocupante} />
              </TabPanel>
            )}
          </Box>
        </TabContext>
      </section>
    </div>
  );
};

export default AddPerson;
