import { useState, useEffect, useContext } from "react";
import styles from "./Expenditure.module.css";
import MyCard from "../MyCard";
import AddExpenditure from "../AddExpenditure";
import MyDataGrid from "../MyDataGrid";
import Utils from "../../Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import MySelect from "../MySelect";
import { get, post, remove, put } from "../../services/api";
import Botao from "../Botao";
import { saveAs } from "file-saver";
import { ApplicationContext } from "../../contexts/Application";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Button } from "@mui/material";
import { ExpenditureModuleContext } from "../../contexts/ExpenditureModule";

function Expenditure() {
  const { setLoading, setTitle, setMessages } = useContext(ApplicationContext);

  const {
    tipo,
    setTipo,
    mes,
    setMes,
    ano,
    setAno,
    meses,
    anos,
    tipos,
    consumoFechado,
    setConsumoFechado,
  } = useContext(ExpenditureModuleContext);

  const [rows, setRows] = useState([]);

  const [editingRows, setEditingRows] = useState([]);

  useEffect(() => {
    setTitle("Consumos");
  }, [setTitle]);

  useEffect(() => {
    setMessages({ type: null, values: null });
  }, [tipo, mes, ano, setMessages]);

  useEffect(() => {
    const filter = {
      idTipoConsumo: tipo,
      mes,
      ano,
    };

    post("/consumo/filter", filter).then((resposta) => {
      setRows(
        resposta.data.map((consumo) => ({
          id: consumo.id,
          tipo: consumo.tipoConsumo.descricao,
          quantidade: consumo.quantidade,
          valorUnitario: consumo.valorUnitario,
          valorTotal: consumo.valorTotal,
          mes: consumo.mes,
          ano: consumo.ano,
          fechado: consumo.fechado,
        }))
      );
    });
  }, [tipo, mes, ano, consumoFechado]);

  const salvaConsumo = async (consumo) => {
    setLoading(true);
    const consumoAInserir = {
      tipoConsumo: { id: tipo },
      mes,
      ano,
      valorTotal: consumo.valorTotal,
      valorUnitario: consumo.valorUnitario,
      quantidade: consumo.quantidade,
    };
    const resposta = await post("/consumo/save", consumoAInserir);
    if (resposta.messageType === "SUCCESS") {
      setRows([
        ...rows,
        {
          id: resposta.data.id,
          tipo: resposta.data.tipoConsumo.descricao,
          quantidade: resposta.data.quantidade,
          valorUnitario: resposta.data.valorUnitario,
          valorTotal: resposta.data.valorTotal,
          mes: resposta.data.mes,
          ano: resposta.data.ano,
        },
      ]);
    }

    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    window.scrollTo(0, 0);
    setLoading(false);
  };

  const handleEdit = (id) => {
    setEditingRows([...editingRows, id]);
  };

  const handleCancel = (id) => {
    setEditingRows(editingRows.filter((rowId) => rowId !== id));
  };

  const handleUpdate = async (params) => {
    setLoading(true);
    try {
      const resposta = await put(`/consumo/update/${params.row.id}`, {
        valorTotal: params.row.valorTotal,
        quantidade: params.row.quantidade,
      });
      if (resposta.messageType === "SUCCESS") {
        setEditingRows(editingRows.filter((rowId) => rowId !== params.row.id));
      }
      setMessages({
        type: resposta.messageType.toLowerCase(),
        values: resposta.messages,
      });
      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleExclude = async (id) => {
    setLoading(true);
    const resposta = await remove(`/consumo/remove/${id}`);

    if (resposta.messageType === "SUCCESS") {
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
    }
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    window.scrollTo(0, 0);
    setLoading(false);
  };

  const fechaConsumo = async () => {
    setLoading(true);
    console.log("Fechando consumo", ano);
    const resposta = await get(`/consumo/close/${mes}/${ano}`);
    if (resposta.messageType === "SUCCESS") {
      setConsumoFechado(true);
    }
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    setLoading(false);
  };

  const abreConsumo = async () => {
    setLoading(true);
    console.log("Abrindo consumo");
    const resposta = await get(`/consumo/open/${mes}/${ano}`);
    if (resposta.messageType === "SUCCESS") {
      setConsumoFechado(false);
    }
    setMessages({
      type: resposta.messageType.toLowerCase(),
      values: resposta.messages,
    });
    setLoading(false);
  };
  const gerarPlanilha = async (tipoUnidade) => {
    setLoading(true);
    const resposta = await get(
      `/unidade/relatorio/${mes}/${ano}/${tipoUnidade}`
    );
    const blob = Utils.b64toBlob(
      resposta.data,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
    setLoading(false);
    saveAs(blob, `planilha_${mes}_${ano}.xlsx`);
  };

  const columns = [
    {
      field: "tipo",
      headerName: "Tipo",
      editable: false,
      width: 150,
    },
    {
      field: "valorUnitario",
      headerName: "Valor da Unidade (R$)",
      type: "number",
      width: 180,
      editable: false,
    },
    {
      field: "quantidade",
      headerName: "Quantidade Gasta",
      type: "number",
      width: 150,
      editable: true,
    },
    {
      field: "valorTotal",
      headerName: "Valor Total (R$)",
      type: "number",
      width: 150,
      editable: true,
    },
    {
      field: "delete",
      headerName: "Ações",
      sortable: false,
      width: 100,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (editingRows.includes(params.id)) {
          return (
            <div>
              <SaveIcon
                color="primary"
                style={{ cursor: "pointer" }}
                titleAccess="Salvar"
                onClick={() => handleUpdate(params)}
              />
              <CloseIcon
                color="action"
                style={{ cursor: "pointer" }}
                titleAccess="Cancelar"
                onClick={() => handleCancel(params.row.id)}
              />
            </div>
          );
        } else {
          return (
            <div>
              {!params.row.fechado && (
                <EditIcon
                  color="warning"
                  style={{ cursor: "pointer" }}
                  titleAccess="Editar"
                  onClick={() => handleEdit(params.row.id)}
                />
              )}
              {!params.row.fechado && (
                <DeleteIcon
                  color="error"
                  style={{ cursor: "pointer" }}
                  titleAccess="Excluir"
                  onClick={() => handleExclude(params.row.id)}
                />
              )}
            </div>
          );
        }
      },
    },
  ];

  return (
    <>
      <div className={styles.bill}>
        <section className={styles.billHeader}>
          <div className={styles.billMesAno}>
            <MySelect
              items={tipos}
              label="Tipo"
              method={(tipo) => setTipo(tipo)}
              value={tipo}
            />
            <MySelect
              items={meses}
              label="Mês"
              method={(mes) => setMes(mes)}
              value={mes}
            />
            <MySelect
              items={anos}
              label="Ano"
              method={(ano) => setAno(ano)}
              value={ano}
            />
            {!consumoFechado && (
              <Button
                variant="outlined"
                onClick={() => fechaConsumo()}
                endIcon={<LockIcon />}
              >
                {" "}
                Fechar Mês
              </Button>
            )}

            {consumoFechado && (
              <Button
                variant="outlined"
                onClick={() => abreConsumo()}
                endIcon={<LockOpenIcon />}
              >
                Abrir Mês
              </Button>
            )}

            {consumoFechado && (
              <Botao onClick={() => gerarPlanilha("Residencial")}>
                Planilha Residencial
              </Botao>
            )}

            {consumoFechado && (
              <Botao onClick={() => gerarPlanilha("Shopping")}>
                Planilha Shopping
              </Botao>
            )}
          </div>
          {tipo !== "" && !consumoFechado && (
            <MyCard>
              <div className={styles.billAddBill}>
                <AddExpenditure add={salvaConsumo} tipo={tipo} />
              </div>
            </MyCard>
          )}
          <MyDataGrid rows={rows} columns={columns} />
        </section>
      </div>
    </>
  );
}

export default Expenditure;
