import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./auth";
import { get } from "../services/api";
export const PeopleControlModuleContext = createContext();
export const PeopleControlModuleProvider = ({ children }) => {
  const { authenticated } = useContext(AuthContext);

  const [personFilter, setPersonFilter] = useState({
    tipo: "",
    unidade: "",
    nomePessoa: "",
    rg: "",
    cpf: "",
    pageNumber: 0,
    totalElements: 0,
    pageSize: 10,
  });

  const [vehicleFilter, setVehicleFilter] = useState({
    placa: "",
    marca: "",
    modelo: "",
    cor: "",
    nomePessoa: "",
    pageNumber: 0,
    totalElements: 0,
    pageSize: 10,
  });

  const [tipoFiltro, setTipoFiltro] = useState("");
  const [unidades, setUnidades] = useState([]);
  const [tipos, setTipos] = useState([]);

  useEffect(() => {
    if (authenticated) {
      get("/tipopessoa/all").then((resposta) => {
        let tipos = resposta.data.map((tipo) => ({
          label: tipo.descricao,
          value: tipo.id,
        }));
        setTipos([{ label: "Selecione", value: "" }, ...tipos]);
      });
    }
  }, [authenticated]);

  return (
    <PeopleControlModuleContext.Provider
      value={{
        personFilter,
        setPersonFilter,
        vehicleFilter,
        setVehicleFilter,
        tipoFiltro,
        setTipoFiltro,
        tipos,
        setTipos,
        unidades,
        setUnidades,
      }}
    >
      {children}
    </PeopleControlModuleContext.Provider>
  );
};

export default PeopleControlModuleContext;
