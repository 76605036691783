import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    DataGrid: {
      rowOdd: {
        backgroundColor: "#fff",
      },
      rowEven: {
        backgroundColor: "#f0f0f0",
      },
    },
    success: {
      main: "#13201b",
      light: "#07550b",
    },
    warning: {
      main: "#E0B66E",
      light: "#ffeabe",
    },
    primary: {
      main: "#413520",
    },
    secondary: {
      main: "#7c653d",
    },
    text: {
      primary: "#413520",
    },
  },
});

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
export default Theme;
