import { useState, useEffect, useContext, useRef } from "react";
import styles from "./Person.module.css";
import MySelect from "../MySelect";
import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import MyPagedDataGrid from "../MyPagedDataGrid";
import PeopleControlModuleContext from "../../contexts/PeopleControlModule";
import { useNavigate } from "react-router-dom";
import { get } from "../../services/api";
import Botao from "../Botao";
import MyCard from "../MyCard";
import Utils from "../../Utils";
import MaskedInput from "react-text-mask";
import dayjs from "dayjs";
import { ApplicationContext } from "../../contexts/Application";

function Person() {
  const {
    personFilter,
    setPersonFilter,
    tipos,
    unidades,
    setUnidades,
    setTipoFiltro,
  } = useContext(PeopleControlModuleContext);

  const [rows, setRows] = useState([]);
  const [filtro, setFiltro] = useState(personFilter);
  const navigate = useNavigate();
  const unidadeRef = useRef();
  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
  const { setTitle, setMessages } = useContext(ApplicationContext);

  useEffect(() => {
    setTitle("Residentes/Visitantes");
    setMessages({ type: null, values: null });
  }, [setTitle, setMessages]);

  const filtrarOcupante = () => {
    if (
      filtro.tipo ||
      filtro.cpf ||
      filtro.rg ||
      filtro.nomePessoa ||
      filtro.unidade
    ) {
      const path =
        "/ocupante/filter?" +
        (filtro.tipo ? `&idTipoPessoa=${filtro.tipo}` : "") +
        `&pageNo=${filtro.pageNumber}` +
        `&pageSize=${filtro.pageSize}` +
        (filtro.rg ? `&rg=${filtro.rg}` : "") +
        (filtro.cpf ? `&cpf=${filtro.cpf}` : "") +
        (filtro.nomePessoa ? `&nomePessoa=${filtro.nomePessoa}` : "") +
        (filtro.unidade ? `&idUnidade=${filtro.unidade.valor}` : "");

      console.log(path);

      get(path).then((resposta) => {
        setFiltro((prevState) => ({
          ...prevState,
          totalElements: resposta.totalElements,
        }));

        setRows(
          resposta.data.map((ocupante) => ({
            id: ocupante.id,
            unidade: ocupante.estadia.unidade.descricao,
            tipo: ocupante.tipoPessoa.descricao,
            nome: ocupante.pessoa.nome,
            rg: ocupante.pessoa.rg,
            cpf: ocupante.pessoa.cpf,
            dataEstadia:
              (ocupante.estadia.chegada
                ? dayjs(ocupante.estadia.chegada).format("DD/MM/YYYY") + " à  "
                : "Indeterminado") +
              (ocupante.estadia.saida
                ? dayjs(ocupante.estadia.saida).format("DD/MM/YYYY")
                : "Indeterminado"),
            celular: ocupante.pessoa.celular,
          }))
        );
      });
    } else {
      setRows([]);
    }
  };

  useEffect(() => {
    filtrarOcupante();
    //eslint-disable-next-line
  }, [filtro.tipo, filtro.pageNumber, filtro.pageSize, filtro.unidade]);

  const handleInputChange = async (event) => {
    if (event) {
      const inputValue = event.target.value;
      if (inputValue !== null && inputValue && inputValue.length > 1) {
        setLoadingAutocomplete(true);
        get(`/unidade/findResidencial?descricao=${inputValue}`).then(
          (resposta) => {
            setUnidades(
              resposta.data.map((unidade) => ({
                label: unidade.descricao,
                valor: unidade.id,
              }))
            );
          }
        );
      }
    }
  };

  const handlePageChange = async (model, details) => {
    setFiltro((prevState) => ({
      ...prevState,
      ...{ pageNumber: model.page, pageSize: model.pageSize },
    }));
  };

  const handleEdit = (id) => {
    setPersonFilter(filtro);
    setTipoFiltro("P");
    navigate(`/peoplemodule/addperson?idOcupante=${id}`);
  };

  const handleNew = (id) => {
    setTipoFiltro("P");
    setPersonFilter(filtro);
    navigate(`/peoplemodule/addperson`);
  };

  const columns = [
    {
      field: "unidade",
      headerName: "Unidade",
      type: "string",
      width: 70,
      editable: false,
    },
    {
      field: "tipo",
      headerName: "Tipo",
      editable: false,
      width: 120,
    },
    {
      field: "nome",
      headerName: "Nome",
      type: "string",
      width: 250,
      editable: false,
    },
    {
      field: "rg",
      headerName: "RG",
      type: "string",
      width: 100,
      editable: true,
    },
    {
      field: "cpf",
      headerName: "CPF",
      type: "string",
      width: 130,
      editable: true,
    },
    {
      field: "celular",
      headerName: "Celular",
      type: "string",
      width: 150,
      editable: true,
    },
    {
      field: "dataEstadia",
      headerName: "Período",
      type: "string",
      width: 200,
      editable: true,
    },
    {
      field: "acoes",
      headerName: "Editar",
      sortable: false,
      width: 70,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <EditIcon
              color="warning"
              style={{ cursor: "pointer" }}
              titleAccess="Editar"
              onClick={() => handleEdit(params.row.id)}
            />
          </div>
        );
      },
    },
  ];

  const handleChange = (name, value) => {
    setFiltro((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleKeyDownButtonAdd = (event) => {
    if (event.key !== "Enter") {
      return;
    }
    filtrarOcupante();
  };

  return (
    <>
      <div className={styles.person}>
        <section className={styles.personHeader}>
          <MyCard>
            <div
              style={{
                margin: "40px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <div className={styles.personFilter}>
                <Autocomplete
                  sx={{ minWidth: 140 }}
                  value={filtro.unidade}
                  disablePortal
                  loading={loadingAutocomplete}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onInputChange={handleInputChange}
                  onChange={(event, novaUnidade) =>
                    handleChange("unidade", novaUnidade)
                  }
                  id="combo-box-demo"
                  options={unidades}
                  renderInput={(params) => (
                    <TextField
                      inputRef={unidadeRef}
                      {...params}
                      label="Unidade"
                    />
                  )}
                />

                <MySelect
                  items={tipos}
                  label="Tipo"
                  method={(tipo) => handleChange("tipo", tipo)}
                  value={filtro.tipo}
                />

                <TextField
                  sx={{ width: "60%" }}
                  id="nomePessoa"
                  name="nomePessoa"
                  label="Nome"
                  value={filtro.nomePessoa}
                  onKeyDown={(event) => handleKeyDownButtonAdd(event)}
                  onChange={(event) =>
                    handleChange(
                      "nomePessoa",
                      event.target.value
                        ? event.target.value.toUpperCase()
                        : null
                    )
                  }
                />

                <TextField
                  id="rg"
                  name="rg"
                  label="RG"
                  sx={{ width: "30%" }}
                  value={filtro.rg}
                  onKeyDown={(event) => handleKeyDownButtonAdd(event)}
                  onChange={(event) => handleChange("rg", event.target.value)}
                />

                <TextField
                  sx={{ width: "30%" }}
                  id="cpf"
                  name="cpf"
                  label="CPF"
                  value={filtro.cpf}
                  onChange={(event) => handleChange("cpf", event.target.value)}
                  onKeyDown={(event) => handleKeyDownButtonAdd(event)}
                  InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: {
                      mask: Utils.cpfMask,
                      placeholder: "999.999.999-99",
                    },
                  }}
                />
              </div>
              <div className={styles.botao} style={{ marginLeft: "auto" }}>
                <Botao onClick={filtrarOcupante}>Pesquisar</Botao>
              </div>
            </div>
          </MyCard>
          <div
            className={styles.botao}
            style={{
              justifyContent: "flex-end",
              display: "flex",
              marginTop: "30px",
              marginBottom: "-30px",
              height: "50px",
            }}
          >
            <Stack spacing={2} direction="row">
              <Button onClick={handleNew} variant="outlined">
                Novo Cadastro
              </Button>
            </Stack>
          </div>
          <MyPagedDataGrid
            rows={rows}
            columns={columns}
            handlePageChange={handlePageChange}
            pageNumber={filtro.pageNumber}
            pageSize={filtro.pageSize}
            totalElements={filtro.totalElements}
          />
        </section>
      </div>
    </>
  );
}

export default Person;
