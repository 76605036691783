import PatternPage from "./components/PatternPage";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Login from "./components/Login";
import Reading from "./components/Reading";
import Expenditure from "./components/Expenditure";
import { AuthProvider, AuthContext } from "./contexts/auth";
import { useContext } from "react";
import AdditionalCharge from "./components/AdditionalCharge";
import Configuration from "./components/Configuration";
import Theme from "./components/Theme";
import ApplicationProvider from "./contexts/Application";
import ConsolidatedReport from "./components/ConsolidatedReport";
import UnitReport from "./components/UnitReport";
import Unit from "./components/Unit";
import ExpenditureModuleProvider from "./contexts/ExpenditureModule";
import AddPerson from "./components/AddPerson";
import Person from "./components/Person";
import { PeopleControlModuleProvider } from "./contexts/PeopleControlModule";
import Vehicles from "./components/Vehicles";

function AppRoutes() {
  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext);

    if (loading) {
      return <div className="loading">Carregando...</div>;
    }

    if (!authenticated) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  return (
    <Theme>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <ApplicationProvider>
                  <Private>
                    <PatternPage />
                  </Private>
                </ApplicationProvider>
              }
            >
              <Route
                path="/expendituremodule"
                element={
                  <ExpenditureModuleProvider>
                    <Outlet />
                  </ExpenditureModuleProvider>
                }
              >
                <Route index element={<Reading />} />
                <Route path="expenditure" element={<Expenditure />} />
                <Route path="unit" element={<Unit />} />
                <Route path="report" element={<ConsolidatedReport />} />
                <Route path="unitreport" element={<UnitReport />} />
                <Route path="additionalcharge" element={<AdditionalCharge />} />
                <Route path="configurations" element={<Configuration />} />
              </Route>
              <Route
                path="/peoplemodule"
                element={
                  <PeopleControlModuleProvider>
                    <Outlet />
                  </PeopleControlModuleProvider>
                }
              >
                <Route index element={<Person />} />
                <Route path="addperson" element={<AddPerson />} />
                <Route path="vehicles" element={<Vehicles />} />
              </Route>

              <Route path="*" element={<div>Página não encontrada</div>} />
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </Theme>
  );
}

export default AppRoutes;
