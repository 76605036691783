export const Utils = {
  meses: () => [
    { value: 1, label: "Jan" },
    { value: 2, label: "Fev" },
    { value: 3, label: "Mar" },
    { value: 4, label: "Abr" },
    { value: 5, label: "Mai" },
    { value: 6, label: "Jun" },
    { value: 7, label: "Jul" },
    { value: 8, label: "Ago" },
    { value: 9, label: "Set" },
    { value: 10, label: "Out" },
    { value: 11, label: "Nov" },
    { value: 12, label: "Dez" },
  ],
  telefoneMask: () => [
    "(",
    /[0-9]/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],

  celularMask: () => [
    "(",
    /[0-9]/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],

  cpfMask: () => [
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ],

  cepMask: () => [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
  placaMask: () => [
    /[A-Za-z]/,
    /[A-Za-z]/,
    /[A-Za-z]/,
    "-",
    /\d/,
    /[A-Za-z0-9]/,
    /\d/,
    /\d/,
  ],

  getDaysOfMonth: (year, month) => {
    const daysInMonth = new Date(year, month, 0).getDate();
    let days = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const label = day.toString().padStart(2, "0");
      days = [...days, { label: label, value: day }];
    }
    return days;
  },

  lastFiveYears: (year) => [
    { label: year - 4, value: year - 4 },
    { label: year - 3, value: year - 3 },
    { label: year - 2, value: year - 2 },
    { label: year - 1, value: year - 1 },
    { label: year, value: year },
  ],

  b64toBlob: (b64Data, contentType) => {
    const sliceSize = 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  },
};

export default Utils;
